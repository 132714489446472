import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useDropzone } from "react-dropzone";
import { CalendarIcon, CheckIcon, CreditCardIcon } from "@heroicons/react/solid";
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Image,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Badge
} from "react-bootstrap";
import { BASE_URL } from '../helpers/config';
import api from './../service/api';
import makeAnimated from 'react-select/animated';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import SetAttachments from "./FormComponents/AttachmentsCreateForm/SetAttachments";
import PhoneNumberInput from './FormComponents/Inputs/PhoneNumberInput';
import ZipCodeInput from './FormComponents/Inputs/ZipCodeInput';
import StoreSizeInput from './FormComponents/Inputs/StoreSizeInput';
import Address1Input from './FormComponents/Inputs/Address1Input';
import Address2Input from './FormComponents/Inputs/Address2Input';
import CityInput from "./FormComponents/Inputs/CityInput";
import EmailInput from "./FormComponents/Inputs/EmailInput";
import NoteTitleInput from "./FormComponents/Inputs/NoteTitleInput";
import NoteDescriptionInput from "./FormComponents/Inputs/NoteDescription";
import SelectServiceFrequency from './FormComponents/Selects/SelectServiceFrequency';
import SelectFloorType from "./FormComponents/Selects/SelectFloorType";
import SelectServiceType from "./FormComponents/Selects/SelectServiceType";
import SelectState from './FormComponents/Selects/SelectState';
import InternalCodeInput from "./FormComponents/Inputs/InternalCodeInput";
import SelectStatus from "./FormComponents/Selects/SelectStatus";
import NameInput from "./FormComponents/Inputs/NameInput";
import SelectUserType from "./FormComponents/Selects/SelectUserType";
import ClientSinceInput from "./FormComponents/Inputs/ClientSinceInput";
import SelectProspectStatus from "./FormComponents/Selects/SelectProspectStatus";
import { SetPermissionsInput } from "./FormComponents/Inputs/SetPermissionsInput";

const CheckIconHtml = ReactDOMServer.renderToString(
  <CheckIcon className="h-50 w-auto" />
);

const SwalWithBootstrapButtons = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary me-3",
      cancelButton: "btn btn-gray",
    },
    buttonsStyling: false,
  })
);

const permissionsByType = {
  user: {
    name: 'Users',
    children: ['getAllUsers', 'createUser', 'updateUser', 'updateUserPassword'],
  },
  prospect: {
    name: 'Prospects',
    children: ['getAllProspects', 'createProspect', 'updateProspect', 'upgradeToStore', 'upgradeToBranch', 'getProspectStatusHistory'],
  },
  branch: {
    name: 'Branches',
    children: ['getAllBranches', 'createBranch', 'updateBranch'],
  },
  note: {
    name: 'Notes',
    children: ['getAllNotes', 'createNote', 'getArchivedNotes', 'updateStatusNote'],
  },
  store: {
    name: 'Stores',
    children: ['getAllStores', 'createStore', 'updateStore'],
  },
  attachment: {
    name: 'Attachments',
    children: ['getAllAttachments', 'createAttachment', 'getArchivedAttachments', 'updateStatusAttachment', 'downloadAttachment'],
  },
  contract: {
    name: 'Contracts',
    children: ['getAllContracts', 'createContract', 'updateStatusContract', 'downloadContract'],
  },
};

export const DropFilesForm = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (files) =>
      setFiles(
        files.map((file) => ({
          ...file,
          preview: URL.createObjectURL(file),
        }))
      ),
  });

  const DropzoneFile = (props) => {
    const { path, preview } = props;

    return (
      <Col xs={6} className="dropzone-preview">
        <Image src={preview} className="dropzone-image" />
        <Card.Text className="dropzone-filename">{path}</Card.Text>
      </Col>
    );
  };

  return (
    <>
      <Form
        {...getRootProps({
          className:
            "dropzone rounded d-flex align-items-center justify-content-center mb-4",
        })}
      >
        <Form.Control {...getInputProps()} />
        <div className="dz-default dz-message text-center">
          <p className="dz-button mb-0">Drop files here to upload</p>
        </div>
      </Form>
      <Row className="dropzone-files">
        {files.map((file) => (
          <DropzoneFile key={file.path} {...file} />
        ))}
      </Row>
    </>
  );
};

export const CardDetailsForm = () => {
  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  return (
    <Card as="form" border="0" className="shadow p-3 pb-4 mb-4">
      <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
        <h5 className="mb-0">Card details</h5>
      </Card.Header>
      <Card.Body className="p-0 p-md-4">
        <Row className="justify-content-center">
          <Col xs={12}>
            <Form.Group id="cardNameLabel">
              <RequiredLabel
                label={
                  <span className="small text-dark">
                    (Full name as displayed on card)
                  </span>
                }
              />
              <InputGroup className="mb-4">
                <Form.Control required type="text" placeholder="Name on Card" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-4">
            <Form.Group id="cardNumberLabel">
              <RequiredLabel label="Card Number" />
              <InputGroup>
                <InputGroup.Text className="text-gray-600">
                  <CreditCardIcon className="icon icon-xs" />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="number"
                  placeholder="0000 0000 0000 0000"
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={6} className="mb-4 mb-lg-0">
            <Form.Group id="cardCVCLabel">
              <RequiredLabel label="CVC" />
              <Form.Control required type="number" placeholder="CVC" />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group id="cardExpiryLabel">
              <RequiredLabel label="Card Expiry" />
              <InputGroup>
                <InputGroup.Text className="text-gray-600">
                  <CalendarIcon className="icon icon-xs" />
                </InputGroup.Text>
                <Form.Control required type="number" placeholder="MM / YY" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} className="mt-4">
            <Button
              variant="gray-800"
              className="animate-up-2 mt-2"
              type="submit"
            >
              Update
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};


export const GeneralUserInfoForm = () => {
    const navigate = useNavigate();
    const [usersType, setUsersType] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        status: '1',
        userTypeId: '',
    });
    const [passwordErrors, setPasswordErrors] = useState({
        minLength: false,
        number: false,
        uppercase: false,
        specialChar: false,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersTypeResp = await api.get(`${BASE_URL}/userType`);
                const usersTypeData = Object.values(usersTypeResp.data.data);
                setUsersType(usersTypeData);
            } catch (error) {
                await Swal.fire({
                    icon: 'error',
                    title: 'Error loading user types',
                    text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading user types. Please try again later.",
                });
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleChangePermissions = (permission, isSelected) => {
        setSelectedPermissions((prev) => {
            const updatedPermissions = isSelected
                ? [...prev, permission]
                : prev.filter((p) => p !== permission);
            return updatedPermissions;
        });
    };

    const validatePassword = (password) => {
        const errors = {
            minLength: password.length < 8 || password.length > 12,
            number: !/\d/.test(password),
            uppercase: !/[A-Z]/.test(password),
            specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
        };

        setPasswordErrors(errors);
        return !Object.values(errors).includes(true);
    };

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setFormData((prev) => ({
            ...prev,
            password: value,
        }));
        validatePassword(value);
    };

    const showPasswordErrorAlert = () => {
        const errorMessages = [];
        if (passwordErrors.minLength) errorMessages.push("Password must be between 8 and 12 characters");
        if (passwordErrors.number) errorMessages.push("Password must contain at least one number");
        if (passwordErrors.uppercase) errorMessages.push("Password must contain at least one uppercase letter");
        if (passwordErrors.specialChar) errorMessages.push("Password must contain at least one special character");

        Swal.fire({
            icon: 'error',
            title: 'Password Validation Failed',
            html: `<ul>${errorMessages.map(msg => `<li>${msg}</li>`).join('')}</ul>`,
            confirmButtonText: 'Ok',
            customClass: {
                confirmButton: 'btn btn-danger'
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePassword(formData.password)) {
            showPasswordErrorAlert();
            return;
        }

        if (!validatePhoneNumber(formData.phone)) {
            await Swal.fire({
                icon: 'error',
                title: 'Invalid Phone Number',
                text: 'Please enter a valid phone number in the format (XXX) XXX-XXXX.',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-danger'
                }
            });
            return;
        }

        if (!validateEmail(formData.email)) {
            await Swal.fire({
                icon: 'error',
                title: 'Invalid Email Address',
                text: 'Please enter a valid email address (e.g., username@example.com)',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-danger'
                }
            });
            return;
        }

        if (isSubmitting) return;

        setIsSubmitting(true);

        const formPayload = new FormData();
        for (const key in formData) {
            formPayload.append(key, formData[key]);
        }

        const payload = {
            name: formData.name,
            email: formData.email,
            phone_number: formData.phone,
            password: formData.password,
            status: formData.status,
            user_type_id: parseInt(formData.userTypeId, 10),
            permissions: selectedPermissions,
        };

        try {
            await api.post(`${BASE_URL}/user`, payload);
            await Swal.fire({
                icon: 'success',
                title: 'Registered Successfully',
                text: 'The user has been registered successfully.',
            });
            navigate('/users');
        } catch (error) {
            console.error('Error submitting form:', error);
            await Swal.fire({
                icon: 'error',
                title: 'Error creating user',
                text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue registering the user. Please try again later.",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const RequiredLabel = ({ label }) => (
        <Form.Label>
            {label} <span className="text-danger"> * </span>
        </Form.Label>
    );

    return (
        <Card as="form" border="0" className="shadow p-3 pb-4 mb-4" onSubmit={handleSubmit}>
            <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
                <h5 className="mb-0">Add New User</h5>
            </Card.Header>
            <Card.Body className="p-0 p-md-4">
                <Row>
                    <Col md={6} className="mb-3">
                        <Form.Group id="name">
                            <RequiredLabel label="Full name" />
                            <InputGroup>
                                <Form.Control required type="text" name="name" value={formData.name} onChange={handleChange} />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                        <PhoneNumberInput formData={formData} setFormData={setFormData} />
                    </Col>
                    <Col md={6} className="mb-3">
                        <EmailInput value={formData.email} onChange={handleChange} mode="create" />
                    </Col>
                    <Col md={6} className="mb-3">
                        <Form.Group id="password">
                            <RequiredLabel label="Password" />
                            <InputGroup>
                                <Form.Control
                                    required
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handlePasswordChange}
                                    placeholder="***"
                                />
                            </InputGroup>
                            {(passwordErrors.minLength ||
                                passwordErrors.number ||
                                passwordErrors.uppercase ||
                                passwordErrors.specialChar) && (
                                <ul style={{ fontSize: '0.6rem' }}>
                                    {passwordErrors.minLength && <li>Password must be between 8 and 12 characters</li>}
                                    {passwordErrors.number && <li>Password must contain at least one number</li>}
                                    {passwordErrors.uppercase && <li>Password must contain at least one uppercase letter</li>}
                                    {passwordErrors.specialChar && <li>Password must contain at least one special character</li>}
                                </ul>
                            )}
                        </Form.Group>
                    </Col>

                    <Col md={6} className="mb-3">
                        <SelectStatus name="status" value={formData.status} onChange={handleChange} mode="create" />
                    </Col>
                    <Col md={6} className="mb-3">
                        <Form.Group id="userType">
                            <Form.Label>User Type</Form.Label>
                            <InputGroup>
                                <Form.Select
                                    name="userTypeId"
                                    value={formData.userTypeId}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select a user type</option>
                                    {usersType.map((type) => (
                                        <option key={type.id} value={type.id}>
                                            {type.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    {formData.userTypeId !== '1' && (
                        <>
                            <h5 className="my-4">Set Permissions</h5>
                            <Row>
                                {Object.keys(permissionsByType).map((key) => (
                                    <SetPermissionsInput
                                        key={key}
                                        permissions={permissionsByType[key].children}
                                        selectedPermissions={selectedPermissions}
                                        handleChangePermissions={handleChangePermissions}
                                        label={permissionsByType[key].name}
                                    />
                                ))}
                            </Row>
                        </>
                    )}
                </Row>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
            </Card.Body>
        </Card>
    );
};

export const CardUserDetailsForm = ({ user }) => {
  const navigate = useNavigate();
  const [usersType, setUsersType] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    status: '1',
    userTypeId: '1',
    permissions: []
  });
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersTypeResp = await api.get(`${BASE_URL}/userType`);
        const usersTypeData = Object.values(usersTypeResp.data.data);
        setUsersType(usersTypeData);
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: 'Error loading user types',
          text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading user types. Please try again later.",
        });
        console.log(error);
      }
    };

    fetchData();

    if (user) {
      setFormData({
        name: user.name,
        email: user.email,
        phone: user.phone_number,
        status: user.status,
        userTypeId: user.user_type_id,
        permissions: user.permissions,
      });

      setSelectedPermissions(user.permissions);
    }

  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(formData.phone)) {
      await Swal.fire({
        icon: 'error',
        title: 'Invalid Phone Number',
        text: 'Please enter a valid phone number in the format (XXX) XXX-XXXX.',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-danger'
        }
      });
      return;
    }

    if (!validateEmail(formData.email)) {
      await Swal.fire({
        icon: 'error',
        title: 'Invalid Email Address',
        text: 'Please enter a valid email address (e.g., username@example.com).',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-danger'
        }
      });
      return;
    }

    if (isSubmitting) return;

    setIsSubmitting(true);

    const payload = {
      id: user.id,
      name: formData.name,
      email: formData.email,
      phone_number: formData.phone,
      status: formData.status,
      user_type_id: formData.userTypeId,
      permissions: selectedPermissions
    };

    try {
      await api.put(`${BASE_URL}/user/${user.id}`, payload);
      await Swal.fire({
        icon: 'success',
        title: 'Updated Successfully',
        text: 'The user has been updated successfully.',
      });
      navigate('/users');
    } catch (error) {
      console.error('Error submitting form:', error);
      await Swal.fire({
        icon: 'error',
        title: 'Error updating user',
        text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating user. Please try again later.",
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-danger'
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangePermissions = (permission) => {
    setSelectedPermissions((prev) => {
      return prev.includes(permission)
        ? prev.filter((perm) => perm !== permission)
        : [...prev, permission];
    });
  };

  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  return (
    <Card as="form" border="0" className="shadow p-3 pb-4 mb-4" onSubmit={handleSubmit}>
      <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
        <h5 className="mb-0">Update User</h5>
      </Card.Header>
      <Card.Body className="p-0 p-md-4">
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="name">
              <RequiredLabel label="Full name" />
              <InputGroup>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  maxLength={100}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <PhoneNumberInput formData={formData} setFormData={setFormData} mode={"edit"} />
          </Col>
          <Col md={6} className="mb-3">
            <EmailInput value={formData.email} onChange={handleChange} mode="edit" />
          </Col>
          <Col md={6} className="mb-3">
            <SelectStatus name="status" value={formData.status} onChange={handleChange} mode="edit" />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="userTypeId">
                <label>User Type</label>
                <InputGroup>
                <Form.Control
                    type="text"
                    value={usersType.find(type => type.id === formData.userTypeId)?.name || ''}
                    readOnly
                />
                </InputGroup>
            </Form.Group>
            </Col>
          {formData.userTypeId !== '1' && (
            <>
              <h5 className="my-4">Set Permissions</h5>
              <Row>
                {Object.keys(permissionsByType).map((key) => (
                  <SetPermissionsInput
                    key={key}
                    permissions={permissionsByType[key].children}
                    selectedPermissions={selectedPermissions}
                    handleChangePermissions={handleChangePermissions}
                    label={permissionsByType[key].name}
                  />
                ))}
              </Row>
            </>
          )}
        </Row>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Loading...' : 'Update'}
        </Button>
      </Card.Body>
    </Card>
  );
};
/*-----------------------------------------------------------------------------------------------------*/
//Prospects Forms

export const GeneralProspectInfoForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const animatedComponents = makeAnimated();
    const [selectedOptionsFloorType, setSelectedOptionsFloorType] = useState([]);

    const [attachments, setAttachments] = useState([]); // Lista de anexos

    const [formData, setFormData] = useState({
        serviceType: '',
        attachmentType: '',
        floorType: [],
        company: '',
        mainContact: '',
        email: '',
        phone: '',
        title: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        salesRepresentative: '',
        storeSize: '',
        serviceFreqNumber: '1x',
        serviceFreqFormat: 'Day',
        description: '',
        status: '',
        subject: '',
        note: '',
        noteTitle: ''
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    }
    const handleSelectChange = (selected) => {
        setSelectedOptionsFloorType(selected);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loading) return;

        setLoading(true);

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(formData.email)) {
          Swal.fire({
            icon: 'warning',
            title: 'Invalid Email',
            text: 'Please enter a valid email address in the format username@example.com.',
          });
          setLoading(false);
          return;
        }

        const phonePattern = /^\d{10}$/;
        const zipPattern = /^(?:\d{5}|\d{9})$/;

        if (!phonePattern.test(formData.phone)) {
          await Swal.fire({
            icon: 'warning',
            title: 'Invalid Phone Number',
            text: 'Please enter a valid phone number in the format (123) 456-7890.',
          });
          setLoading(false);
          return;
        }

        if (!zipPattern.test(formData.zipCode)) {
          await Swal.fire({
            icon: 'warning',
            title: 'Invalid Zip Code',
            text: 'Please enter a valid zip code in the format 12345.',
          });
          setLoading(false);
          return;
        }

        const formPayload = new FormData();
        for (const key in formData) {
          formPayload.append(key, formData[key]);
        }

        const unformattedPhone = formData.phone.replace(/\D/g, '');
        const unformattedZipCode = formData.zipCode.replace(/\D/g, '');

        const payload = {
          company: formData.company,
          store_size: parseFloat(formData.storeSize) || 0,
          service_frequency: formData.serviceFreqNumber + formData.serviceFreqFormat,
          ...(formData.salesRepresentative ? { user_id: parseInt(formData.salesRepresentative, 10) } : {}),
          main_contact: formData.mainContact,
          title: formData.title,
          phone_number: unformattedPhone,
          email: formData.email,
          prospect_status_id: parseInt(formData.status,10),
          address: {
              address1: formData.address1,
              address2: formData.address2,
              city: formData.city,
              state: formData.state,
              zip_code: unformattedZipCode
          },
          service_type_id: parseInt(formData.serviceType, 10),
          floor_types: selectedOptionsFloorType.map(floor => floor.value),
          note: formData.note,
          note_title: formData.noteTitle,
        };

        try {
          const result = await api.post(`${BASE_URL}/prospect`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          saveAttachments(result);
          await Swal.fire({
            icon: 'success',
            title: 'Registered Successfully',
            text: 'The prospect has been registered successfully.',
          });
          navigate('/clients/prospects');
        } catch (error) {
          console.error('Error submitting form:', error);
          await Swal.fire({
            icon: 'error',
            title: 'Error creating prospective client',
            text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue registering the prospective client. Please try again later.",
          });
        } finally {
          setLoading(false);
        }
    };

    const saveAttachments = async (result) => {
        try {
          for (let attachment of attachments) {
            const responseInfo = await api.post(`${BASE_URL}/attachments/infos`, {
              attachment_type_id: attachment.type_id,
              entity: "prospect",
              entity_id: result.data.id,
              title: attachment.title,
              description: attachment.description,
            });

            const payload = {
              attachment_info_id: responseInfo.data.id,
              files: Array.from(attachment.files),
              attachment_type_id: attachment.type_id,
            };

            await api.post(`${BASE_URL}/attachments`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
          }
        } catch (error) {
          console.error("Failed to save attachments:", error);
          SwalWithBootstrapButtons.fire("Error", "Failed to save attachments.", "error");
        }
      };

    const RequiredLabel = ({ label }) => (
        <Form.Label>
          {label}
          <span className="text-danger"> * </span>
        </Form.Label>
    );
  return (
    <>
      <Card as="form" encType="multipart/form-data" border="0" className="shadow-lg p-4 mb-4" onSubmit={handleSubmit}>
        <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
            <h5 className="mb-0">Add New Prospect</h5>
          </Card.Header>
        <Card.Body>
          <Row>
          <Col md={6} className="mb-3">
                <Form.Group id="company">
                  <RequiredLabel label="Company" />
                  <InputGroup>
                    <Form.Control
                    required type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    maxLength={100}
                    />
                  </InputGroup>
                </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
                <Form.Group id="mainContact">
                  <RequiredLabel label="Main Contact" />
                  <InputGroup>
                    <Form.Control
                    required type="text"
                    name="mainContact"
                    value={formData.mainContact}
                    onChange={handleChange}
                    maxLength={100}
                    />
                  </InputGroup>
                </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
                  <EmailInput value={formData.email} onChange={(e) => handleChange(e)} mode="create"/>
            </Col>
            <Col md={6} className="mb-3">
                <PhoneNumberInput formData={formData} setFormData={setFormData} />
            </Col>
            <Col md={6} className="mb-3">
                <Form.Group id="title">
                  <RequiredLabel label="Title" />
                  <InputGroup>
                    <Form.Control
                     required
                     type="text"
                     name="title"
                     value={formData.title}
                     onChange={handleChange}
                     maxLength={100}
                     />
                  </InputGroup>
                </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <RequiredLabel label="Status" />
              <SelectProspectStatus
                selectedProspectStatus={formData.status}
                onProspectStatusChange={handleChange}
                mode="create"
            />
            </Col>
            <h5 className="my-4">Location</h5>
              <Col md={6} className="mb-3">
                <Address1Input value={formData.address1} onChange={(e) => handleChange(e)} mode="create"/>
              </Col>
              <Col md={6} className="mb-3">
                <Address2Input value={formData.address2} onChange={(e) => handleChange(e)} mode="create"/>
              </Col>
              <Col md={6} className="mb-3">
                <CityInput value={formData.city} onChange={(e) => handleChange(e)} mode="create"/>
              </Col>
              <Col sm={6} className="mb-3">
                  <SelectState name="state" value={formData.state} onChange={handleChange}/>
              </Col>
              <Col md={6} className="mb-3">
                <ZipCodeInput formData={formData} setFormData={setFormData}/>
              </Col>
              <h5 className="my-4">Sales Information</h5>
              <Col md={6} className="mb-3">
                  <SelectServiceType selectedServiceType={formData.serviceType} onServiceTypeChange={handleChange} mode="create"/>
              </Col>
              <Col md={6} className="mb-3">
                <SelectUserType
                  userTypeId={5}
                  selectedType={formData.salesRepresentative}
                  onTypeChange={(value) => setFormData(prev => ({ ...prev, salesRepresentative: value }))}
                  nameLabel={"Sales Representative"}
                />
              </Col>
              <Col md={6} className="mb-3">
                <StoreSizeInput
                  value={formData.storeSize}
                  onChange={(value) => setFormData((prev) => ({ ...prev, storeSize: value }))}
                />
              </Col>
              <SelectServiceFrequency
                  formData={formData}
                  handleChange={handleChange}
              />
              <Col md={6} className="mb-3">
                <SelectFloorType
                  selectedOptions={selectedOptionsFloorType}
                  onSelectChange={handleSelectChange}
                  animatedComponents={animatedComponents}
               />
              </Col>
           </Row>

          <h5 className="my-4">Additional Information</h5>
            <Col md={6} className="mb-3">
              <SetAttachments
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Col>
            <h5 className="my-4">Leave a Note</h5>
              <Col md={12} className="mb-3">
                  <NoteTitleInput
                      noteTitle={formData.noteTitle}
                      onChange={handleChange}
                  />
              </Col>
              <Col md={12} className="mb-3">
                <NoteDescriptionInput
                      note={formData.note}
                      onChange={handleChange}
                      isNoteTitleProvided={!!formData.noteTitle}
                  />
              </Col>
           <Button variant="primary" type="submit">
              Submit
            </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export const EditProspectInfoForm = ({ prospect }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      id: '',
      serviceType: '1',
      company: '',
      mainContact: '',
      email: '',
      phone: '',
      title: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      salesRepresentative: '',
      storeSize: '',
      serviceFreqNumber: '1x',
      serviceFreqFormat: 'Day',
      floorType: [],
      status: '1',
      subject: '',
    });
    const [selectedOptionsFloorType, setSelectedOptionsFloorType] = useState([]);
    const [loading, setLoading] = useState(false); // Estado de carregamento
    const animatedComponents = makeAnimated();

    useEffect(() => {

      if (prospect) {
        setFormData({
          id: prospect.id,
          serviceType: prospect.service_type_id || '1',
          company: prospect.company || '',
          mainContact: prospect.main_contact || '',
          email: prospect.email || '',
          phone: prospect.phone_number || '',
          title: prospect.title || '',
          address1: prospect.address.address1 || '',
          address2: prospect.address.address2 || '',
          city: prospect.address.city || '',
          state: prospect.address.state || '0',
          zipCode: prospect.address.zip_code || '',
          salesRepresentative: (prospect.user && prospect.user.id) || '',
          storeSize: prospect.store_size || '',
          serviceFreqNumber: prospect.service_frequency?.substring(0, 2) || '',
          serviceFreqFormat: prospect.service_frequency?.substring(2) || '',
          floorType: prospect.floor_types || [],
          status: prospect.status.id || '1',
          subject: prospect.subject || '',
        });
      }
    }, [prospect]);

    useEffect(() => {
      if (formData.floorType) {
        const preselectedOptions = formData.floorType.map(floor => ({
          label: floor.floor_type_name,
          value: floor.id
        }));
        setSelectedOptionsFloorType(preselectedOptions);
      }
    }, [formData.floorType]);

    const handleSelectChange = (selected) => {
      setSelectedOptionsFloorType(selected);
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (loading) return;
      setLoading(true);

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(formData.email)) {
        Swal.fire({
          title: 'Invalid Email',
          text: 'Please enter a valid email address.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        setLoading(false);
        return;
      }

      const phonePattern = /^\d{10}$/;
      const zipPattern = /^(?:\d{5}|\d{9})$/;

      if (!phonePattern.test(formData.phone)) {
          await SwalWithBootstrapButtons.fire(
              "Invalid Phone Number",
              "Please enter a valid phone number in the format (123) 456-7890.",
              "warning"
          );
          setLoading(false);
          return;
      }
      if (!zipPattern.test(formData.zipCode)) {
          await SwalWithBootstrapButtons.fire(
              "Invalid Zip Code",
              "Please enter a valid zip code in the format 12345 or 12345-6789.",
              "warning"
          );
          setLoading(false);
          return;
      }

      const payload = {
        id: formData.id,
        company: formData.company,
        store_size: parseInt(formData.storeSize, 10),
        service_frequency: formData.serviceFreqNumber + formData.serviceFreqFormat,
        ...(formData.salesRepresentative ? { user_id: parseInt(formData.salesRepresentative, 10) } : {}),
        main_contact: formData.mainContact,
        title: formData.title,
        phone_number: formData.phone,
        email: formData.email,
        prospect_status_id: parseInt(formData.status,10),
        address: {
          address1: formData.address1,
          address2: formData.address2,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zipCode
        },
        service_type_id: parseInt(formData.serviceType, 10),
        floor_types: selectedOptionsFloorType.map(floor => floor.value),
      };

      try {
        await api.put(`${BASE_URL}/prospect/${prospect.id}`, payload);
        await Swal.fire({
            icon: 'success',
            title: 'Updated Successfully',
            text: 'The prospect has been updated successfully.',
          });
        navigate('/clients/prospects');
      } catch (error) {
        console.error('Error submitting form:', error);
        await SwalWithBootstrapButtons.fire(
            "Error updating the prospective client",
            error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue updating the prospective client. Please try again later.",
            "error"
          );
      } finally {
        setLoading(false);
      }
    };

    const RequiredLabel = ({ label }) => (
      <Form.Label>
        {label}
        <span className="text-danger"> * </span>
      </Form.Label>
    );

    return (
      <Card as="form" border="0" className="shadow p-3 pb-4 mb-4" onSubmit={handleSubmit}>
        <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
          <h5 className="mb-0">Update Prospect Information</h5>
        </Card.Header>
        <Card.Body className="p-0 p-md-4">
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="company">
                <RequiredLabel label="Company" />
                <InputGroup>
                  <Form.Control
                    name="company"
                    required
                    type="text"
                    value={formData.company}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="mainContact">
                <RequiredLabel label="Main Contact" />
                <InputGroup>
                  <Form.Control
                    name="mainContact"
                    required
                    type="text"
                    value={formData.mainContact}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
                <EmailInput value={formData.email} onChange={(e) => handleChange(e)} mode="edit"/>
            </Col>
            <Col md={6} className="mb-3">
              <PhoneNumberInput formData={formData} setFormData={setFormData} mode={"edit"}/>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="title">
                <RequiredLabel label="Title" />
                <InputGroup>
                  <Form.Control
                    name="title"
                    required
                    type="text"
                    value={formData.title}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
                <Form.Group id="prospectStatus">
                <Form.Label>
                    Status <span className="text-danger"> * </span>
                </Form.Label>
                <InputGroup>
                    {prospect.status.status_name === 'approved' ? (
                    <Form.Control
                        type="text"
                        value="Approved"
                        readOnly
                        disabled
                    />
                    ) : (
                    <></>
                    )}
                </InputGroup>
                </Form.Group>
                {prospect.status.status_name !== 'approved' && (
                <SelectProspectStatus
                    selectedProspectStatus={formData.status}
                    onProspectStatusChange={handleChange}
                    mode="edit"
                />
                )}
            </Col>
            <h5 className="my-4">Location</h5>
            <Col md={6} className="mb-3">
              <Address1Input value={formData.address1} onChange={(e) => handleChange(e)} mode="edit"/>
            </Col>
            <Col md={6} className="mb-3">
              <Address2Input value={formData.address2} onChange={(e) => handleChange(e)} mode="edit"/>
            </Col>
            <Col md={6} className="mb-3">
              <CityInput value={formData.city} onChange={(e) => handleChange(e)} mode="edit"/>
            </Col>
            <Col sm={6} className="mb-3">
              <SelectState
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  mode="edit"
              />
            </Col>
            <Col md={6} className="mb-3">
              <ZipCodeInput
                  formData={formData}
                  setFormData={setFormData}
                  mode={"edit"}
              />
            </Col>
            <h5 className="my-4">Sales Information</h5>
            <Col md={6} className="mb-3">
                <SelectServiceType selectedServiceType={formData.serviceType} onServiceTypeChange={handleChange} mode="edit"/>
            </Col>
            <Col md={6} className="mb-3">
              <SelectUserType
                userTypeId={5}
                selectedType={formData.salesRepresentative}
                onTypeChange={(value) => setFormData(prev => ({ ...prev, salesRepresentative: value }))}
                nameLabel={"Sales Representative"}
              />
            </Col>
            <Col md={6} className="mb-3">
                <StoreSizeInput
                    value={formData.storeSize}
                    onChange={(value) => setFormData((prev) => ({ ...prev, storeSize: value }))}
                    mode="edit"
                />
            </Col>
               <SelectServiceFrequency formData={formData} handleChange={handleChange} mode="edit"/>
            <Col md={6} className="mb-3">
               <SelectFloorType
                selectedOptions={selectedOptionsFloorType}
                onSelectChange={handleSelectChange}
                animatedComponents={animatedComponents}
                mode="edit"
             />
            </Col>
            <Col xs={12} className="mt-4">
              <Button
                variant="gray-800"
                className="animate-up-2 mt-2"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Update'}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
};

export const ViewProspectInfoForm = ({ prospect }) => {
    return (
      <Card as="form" border="0" className="shadow p-3 pb-4 mb-4">
        <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
          <h5 className="mb-0">View Prospect</h5>
        </Card.Header>
        <Card.Body className="p-0 p-md-4">
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="company">
                <Form.Label>Company</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    value={prospect.company || ""}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="mainContact">
                <Form.Label>Main Contact</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    value={prospect.main_contact || ""}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
                <EmailInput value={prospect.email || ""}  mode="view"/>
            </Col>
            <Col md={6} className="mb-3">
               <PhoneNumberInput
                formData={{ phone: prospect.phone_number || ""}}
                mode="view"
            />
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <InputGroup>
                  <Form.Control required type="text" value={prospect.title || ""} disabled />
                </InputGroup>
              </Form.Group>
            </Col>
            <h5 className="my-4">Location</h5>
            <Col md={6} className="mb-3">
              <Address1Input value={prospect.address.address1 || ""} mode="view"/>
            </Col>
            <Col md={6} className="mb-3">
              <Address2Input value={prospect.address.address2 || ""} mode="view"/>
            </Col>
            <Col md={6} className="mb-3">
                <CityInput value={prospect.address.city} mode="view"/>
            </Col>
            <Col md={6} className="mb-3">
              <SelectState
                  name="state"
                  value={prospect.address.state || ""}
                  mode="view"
                />
            </Col>
            <Col md={6} className="mb-3">
               <ZipCodeInput
                formData={{ zipCode: prospect.address.zip_code || ""}}
                mode="view"
            />
            </Col>
            <h5 className="my-4">Sales Information</h5>
            <Col md={6} className="mb-3">
                <SelectServiceType selectedServiceType={prospect.service_type.service_type_name} mode="view"/>
            </Col>
            <Col md={6} className="mb-3">
              <SelectUserType
                userTypeId={5}
                selectedType={prospect.user && prospect.user.id ? prospect.user.id : ""}
                nameLabel={"Sales Representative"}
                mode="view"
            />

            </Col>
            <Col md={6} className="mb-3">
               <StoreSizeInput
                   value={prospect.store_size || ""}
                    mode="view"
                />
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="frequency">
                <Form.Label>Service Frequency</Form.Label>
                <InputGroup>
                  <Form.Control required type="text" value={prospect.service_frequency || ""} disabled />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="floorType">
                <Form.Label>Floor Types</Form.Label>
                <Form.Select multiple defaultValue={prospect.floor_types || []} disabled>
                  {prospect.floor_types && prospect.floor_types.map((floorType, index) => (
                    <option key={index} value={floorType.floor_type_name.toLowerCase()}>
                      {floorType.floor_type_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Label>Status</Form.Label>
              <SelectProspectStatus
                selectedProspectStatus = {prospect.status.status_name}
                mode="view"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

export const GeneralStoreInfoForm = () => {
  const navigate = useNavigate();
  const [attachmentType, setAttachmentType] = useState([]);
  const [attachments, setAttachments] = useState([]); // Lista de anexos

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    internalCode: '',
    name: '',
    attachmentType: '1',
    file: null,
    description: '',
    status: '1',
    subject: '',
    note: '',
    noteTitle: '',
    since: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const attachmentTypeData = await api.get(`${BASE_URL}/attachmentTypes`);
        let data = Object.values(attachmentTypeData.data.data);
        setAttachmentType(data)
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const validateInternalCode = (code) => {
    const regex = /^[A-Za-z]{4}$/;  // Regex que garante exatamente 4 caracteres alfabéticos
    return regex.test(code);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;
    setLoading(true);

    // Validação do campo internalCode
    if (!validateInternalCode(formData.internalCode)) {
      Swal.fire({
        icon: 'warning',
        title: 'Internal Code Required',
        text: 'Internal Code must be exactly 4 alphabetic characters.',
      });
      setError('Internal Code must be exactly 4 alphabetic characters.');
      setLoading(false);  // Desabilita o carregamento
      return;
    }

    const payload = {
      internal_code: formData.internalCode,
      name: formData.name,
      since: formData.since,
      status: formData.status,
      note: formData.note,
      note_title: formData.noteTitle,
      infos: [{
        attachment_type_id : formData.attachmentType,
        description: formData.description,
      }],
      files: [formData.file]
    };

    try {
      const result = await api.post(`${BASE_URL}/store`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      saveAttachments(result)
      await Swal.fire({
        icon: 'success',
        title: 'Registered Successfully',
        text: 'The Store has been registered successfully.',
      });

      navigate('/clients/stores');
    } catch (error) {
      console.error('Error submitting form:', error);
      await Swal.fire({
          icon: 'error',
          title: 'Error creating store',
          text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue registering the prospective client. Please try again later.",
      });
    } finally {
        setLoading(false);
    }
  };

  const saveAttachments = async (result) => {
    try {
      for (let attachment of attachments) {
        const responseInfo = await api.post(`${BASE_URL}/attachments/infos`, {
          attachment_type_id: attachment.type_id,
          entity: "store",
          entity_id: result.data.id,
          title: attachment.title,
          description: attachment.description,
        });

        const payload = {
          attachment_info_id: responseInfo.data.id,
          files: Array.from(attachment.files),
          attachment_type_id: attachment.type_id,
        };
        await api.post(`${BASE_URL}/attachments`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
      }
    } catch (error) {
      console.error("Failed to save attachments:", error);
      SwalWithBootstrapButtons.fire("Error", "Failed to save attachments.", "error");
    }
  };
  return (
    <Card as="form" encType="multipart/form-data" border="0" className="shadow p-3 pb-4 mb-4" onSubmit={handleSubmit}>
      <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
        <h5 className="mb-0">Add New Store</h5>
      </Card.Header>
      <Card.Body className="p-0 p-md-4">
        <Row>
          <Col md={6} className="mb-3">
            <InternalCodeInput
              value={formData.internalCode}
              onChange={handleChange}
              mode="create"
            />
            {error && <div className="text-danger mt-2">{error}</div>}
          </Col>
          <Col md={6} className="mb-3">
            <NameInput value={formData.name} onChange={handleChange} />
          </Col>
          <Col md={6} className="mb-3">
            <ClientSinceInput value={formData.since} onChange={(value) => setFormData((prev) => ({ ...prev, since: value }))}/>
          </Col>
          <Col md={6} className="mb-3">
            <SelectStatus name="status" value={formData.status} onChange={handleChange}/>
          </Col>
        </Row>

        <h5 className="my-4">Additional Information</h5>
             <Col md={6} className="mb-3">
              <SetAttachments
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Col>
        <h5 className="my-4">Leave a Note</h5>
            <Col md={12} className="mb-3">
              <NoteTitleInput
                noteTitle={formData.noteTitle}
                onChange={handleChange}
              />
            </Col>
            <Col md={12} className="mb-3">
              <NoteDescriptionInput
                note={formData.note}
                onChange={handleChange}
                isNoteTitleProvided={!!formData.noteTitle}
              />
            </Col>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Card.Body>
    </Card>
  );
};

export const GeneralStoreBranchInfoForm = () => {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    serviceType: '1',
    attachmentType: '1',
    floorType: [],
    managerEmail: '',
    managerName: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '1',
    zipCode: '',
    storeSize: '',
    serviceFreqNumber: '1x',
    serviceFreqFormat: 'Day',
    file: null,
    description: '',
    status: '1',
    subject: '',
    note: '',
    noteTitle: '',
    userSalesRep: '',
    userAreaManager: '',
    userSupercontractor: ''
  });
  const animatedComponents = makeAnimated();
  const [attachments, setAttachments] = useState([]); // Lista de anexos

  const [selectedOptionsFloorType, setSelectedOptionsFloorType] = useState([]);

  const handleSelectChange = (selected) => {
    setSelectedOptionsFloorType(selected);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;
    setLoading(true);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Email',
        text: 'Please enter a valid email address in the format username@example.com.',
      });
      setLoading(false);
      return;
    }

    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(formData.phone)) {
      await Swal.fire({
          icon: 'warning',
          title: 'Invalid Phone Number',
          text: 'Please enter a valid phone number in the format (123) 456-7890.',
      });
      setLoading(false);
      return;
    }

    const zipPattern = /^(?:\d{5}|\d{9})$/;
    if (!zipPattern.test(formData.zipCode)) {
        await Swal.fire({
            icon: 'warning',
            title: 'Invalid Zip Code',
            text: 'Please enter a valid zip code in the format 12345 or 12345-6789.',
        });
        setLoading(false);
        return;
    }

    const unformattedPhone = formData.phone.replace(/\D/g, '');
    const unformattedZipCode = formData.zipCode.replace(/\D/g, '');

    const payload = {
      service_type_id: formData.serviceType,
      store_size: formData.storeSize === "" ? null : formData.storeSize,
      service_frequency: formData.serviceFreqNumber + formData.serviceFreqFormat,
      phone_number: unformattedPhone,
      email: formData.email,
      status: formData.status,
      email: formData.email,
      name: formData.name,
      manager_name: formData.managerName,
      manager_email: formData.managerEmail,
      address: {
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip_code: unformattedZipCode
      },
      floor_types: selectedOptionsFloorType.map(floor => floor.value),
      note: formData.note,
      note_title: formData.noteTitle,
      infos: [{
        attachment_type_id : formData.attachmentType,
        description: formData.description,
      }],
      files: [formData.file],
      store_id: storeId,
      users_types: [
        formData.userSalesRep,
        formData.userAreaManager,
        formData.userSupercontractor,
      ].filter(value => value !== '')
    };

    try {
     const result = await api.post(`${BASE_URL}/branch`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      saveAttachments(result)
      await Swal.fire({
        icon: 'success',
        title: 'Registered Successfully',
        text: 'The branch has been registered successfully.',
      });

      navigate(`/clients/stores/${storeId}/branches`);
    } catch (error) {
      console.error('Error submitting form:', error);
      await Swal.fire({
        icon: 'error',
        title: 'Error creating branch',
        text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue registering the prospective client. Please try again later.",
    });
    } finally {
      setLoading(false);
    }
  };
  const saveAttachments = async (result) => {
    try {
      for (let attachment of attachments) {
        const responseInfo = await api.post(`${BASE_URL}/attachments/infos`, {
          attachment_type_id: attachment.type_id,
          entity: "branch",
          entity_id: result.data.id,
          title: attachment.title,
          description: attachment.description,
        });

        const payload = {
          attachment_info_id: responseInfo.data.id,
          files: Array.from(attachment.files),
          attachment_type_id: attachment.type_id,
        };
        await api.post(`${BASE_URL}/attachments`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
      }
    } catch (error) {
      console.error("Failed to save attachments:", error);
      SwalWithBootstrapButtons.fire("Error", "Failed to save attachments.", "error");
    }
  };
  return (
    <Card as="form" border="0" encType="multipart/form-data" className="shadow p-3 pb-4 mb-4" onSubmit={handleSubmit}>
      <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
        <h5 className="mb-0">Add New Branch</h5>
      </Card.Header>
      <Card.Body className="p-0 p-md-4">
        <Row>
          <Col md={6} className="mb-3">
            <PhoneNumberInput formData={formData} setFormData={setFormData} />
          </Col>
          <Col md={6} className="mb-3">
            <SelectStatus name="status" value={formData.status} onChange={handleChange}/>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="name">
              <Form.Label>Branch's Name</Form.Label>
              <InputGroup>
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} maxLength={100}/>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
          <EmailInput value={formData.email} onChange={(e) => handleChange(e)} mode="create"/>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="managerName">
              <Form.Label>Manager's Name</Form.Label>
              <InputGroup>
                <Form.Control type="text" name="managerName" value={formData.managerName} onChange={handleChange} maxLength={100}/>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="managerEmail">
              <Form.Label>Manager's Email</Form.Label>
              <InputGroup>
                <Form.Control type="email" name="managerEmail" value={formData.managerEmail} onChange={handleChange}/>
              </InputGroup>
            </Form.Group>
          </Col>
          <h5 className="my-4">Location</h5>
          <Col md={6} className="mb-3">
              <Address1Input value={formData.address1} onChange={(e) => handleChange(e)} mode="create"/>
          </Col>
          <Col md={6} className="mb-3">
            <Address2Input value={formData.address2} onChange={(e) => handleChange(e)} mode="create"/>
          </Col>
          <Col md={6} className="mb-3">
            <CityInput value={formData.city} onChange={(e) => handleChange(e)} mode="create"/>
          </Col>
          <Col sm={6} className="mb-3">
            <SelectState name="state" value={formData.state} onChange={handleChange}/>
          </Col>
          <Col md={6} className="mb-3">
            <ZipCodeInput formData={formData} setFormData={setFormData}/>
          </Col>
          <h5 className="my-4">Operations Management</h5>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={5}
              selectedType={formData.userSalesRep}
              onTypeChange={(value) => setFormData(prev => ({ ...prev, userSalesRep: value }))}
              nameLabel={"Sales Representative"}
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={2}
              selectedType={formData.userAreaManager}
              onTypeChange={(value) => setFormData(prev => ({ ...prev, userAreaManager: value }))}
              nameLabel={"Area Manager"}
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={6}
              selectedType={formData.userSupercontractor}
              onTypeChange={(value) => setFormData(prev => ({ ...prev, userSupercontractor: value }))}
              nameLabel={"Subcontractor"}
            />
          </Col>
          <Col md={6} className="mb-3">
            <StoreSizeInput
                value={formData.storeSize}
                onChange={(value) => setFormData((prev) => ({ ...prev, storeSize: value }))}
              />
          </Col>
          <Col md={6} className="mb-3">
            <SelectFloorType
              selectedOptions={selectedOptionsFloorType}
              onSelectChange={handleSelectChange}
              animatedComponents={animatedComponents}
            />
          </Col>
          <SelectServiceFrequency
            formData={formData}
            handleChange={handleChange}
          />
          <Col md={6} className="mb-3">
            <SelectServiceType selectedServiceType={formData.serviceType} onServiceTypeChange={handleChange} mode="create"/>
          </Col>
        </Row>
        <h5 className="my-4">Additional Information</h5>
          <Col md={6} className="mb-3">
              <SetAttachments
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Col>
          <h5 className="my-4">Leave a Note</h5>
          <Col md={12} className="mb-3">
            <NoteTitleInput
              noteTitle={formData.noteTitle}
              onChange={handleChange}
            />
          </Col>
          <Col md={12} className="mb-3">
            <NoteDescriptionInput
              note={formData.note}
              onChange={handleChange}
              isNoteTitleProvided={!!formData.noteTitle}
            />
          </Col>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Card.Body>
    </Card>
  );
};

export const EditStoreBranchInfoForm = ({ branch }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: '',
    serviceType: '1',
    company: '',
    mainContact: '',
    email: '',
    phone: '',
    title: '',
    address1: '',
    address2: '',
    city: '',
    state: '1',
    zipCode: '',
    storeSize: null,
    serviceFreqNumber: '1x',
    serviceFreqFormat: 'Day',
    floorType: [],
    status: '1',
    subject: '',
    storeId: '',
    userSalesRep: '',
    userAreaManager: '',
    userSupercontractor: ''
  });
  const [selectedOptionsFloorType, setSelectedOptionsFloorType] = useState([]);
  const [loading, setLoading] = useState(false);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (branch) {
      setFormData({
        id: branch.id,
        serviceType: branch.service_type_id || '1',
        email: branch.email || '',
        phone: branch.phone_number || '',
        name: branch.name || '',
        managerEmail: branch.manager_email || '',
        managerName: branch.manager_name || '',
        address1: branch.address.address1 || '',
        address2: branch.address.address2 || '',
        city: branch.address.city || '',
        state: branch.address.state || '0',
        zipCode: branch.address.zip_code || '',
        storeSize: branch.store_size || '',
        serviceFreqNumber: branch.service_frequency?.substring(0, 2) || '',
        serviceFreqFormat: branch.service_frequency?.substring(2) || '',
        frequencyUnit: branch.frequencyUnit || '0',
        floorType: branch.floor_types || [], // Certifique-se de definir como array aqui
        description: branch.description || '',
        status: branch.status || '0',
        subject: branch.subject || '',
        storeId: branch.store_id,
        userSalesRep: branch.sales_representative || '',
        userAreaManager: branch.area_manager || '',
        userSupercontractor: branch.subcontractor | ''
      });
    }
  }, [branch]);

  useEffect(() => {
    if (formData.floorType) {
      const preselectedOptions = formData.floorType.map(floor => ({
        label: floor.floor_type_name,
        value: floor.id
      }));
      setSelectedOptionsFloorType(preselectedOptions);
    }
  }, [formData.floorType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSelectChange = (selected) => {
    setSelectedOptionsFloorType(selected);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;
    setLoading(true);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      Swal.fire({
        title: 'Invalid Email',
        text: 'Please enter a valid email address.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      setLoading(false);
      return;
    }

    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(formData.phone)) {
      await SwalWithBootstrapButtons.fire(
        "Invalid Phone Number",
        "Please enter a valid phone number in the format (123) 456-7890.",
        "warning"
      );
      setLoading(false);
      return;
    }

    const zipPattern = /^(?:\d{5}|\d{9})$/;
    if (!zipPattern.test(formData.zipCode)) {
      await SwalWithBootstrapButtons.fire(
        "Invalid Zip Code",
        "Please enter a valid zip code in the format 12345 or 12345-6789.",
        "warning"
      );
      setLoading(false);
      return;
    }

    const payload = {
      id: formData.id,
      service_type_id: formData.serviceType,
      store_size: parseInt(formData.storeSize, 10),
      service_frequency: formData.serviceFreqNumber + formData.serviceFreqFormat,
      phone_number: formData.phone,
      email: formData.email,
      status: formData.status,
      email: formData.email,
      name: formData.name,
      manager_name: formData.managerName,
      manager_email: formData.managerEmail,
      address: {
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zipCode
      },
      floor_types: selectedOptionsFloorType.map(floor => floor.value),
      store_id: formData.storeId,
      users_types: [
        formData.userSalesRep,
        formData.userAreaManager,
        formData.userSupercontractor,
      ].filter(value => value !== '')
    };

    const textMessage = "Do you really want to update this branch?";
    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: CheckIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await api.put(`${BASE_URL}/branch/${branch.id}`, payload);
        await Swal.fire({
            icon: 'success',
            title: 'Updated Successfully',
            text: 'The branch has been updated successfully.',
          });
        navigate(`/clients/stores/${branch.store_id}/branches`);
      } catch (error) {
        console.error('Error submitting form:', error);
        await SwalWithBootstrapButtons.fire("Error", error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue. Please try again later.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card as="form" border="0" className="shadow p-3 pb-4 mb-4" onSubmit={handleSubmit}>
      <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
        <h5 className="mb-0">Update Branch's Information</h5>
      </Card.Header>
      <Card.Body className="p-0 p-md-4">
        <Row>
          <Col md={6} className="mb-3">
            <PhoneNumberInput formData={formData} setFormData={setFormData} mode={"edit"}/>
          </Col>
          <Col md={6} className="mb-3">
            <SelectStatus value={formData.status} onChange={handleChange}/>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="name">
              <Form.Label>Branch's Name</Form.Label>
              <InputGroup>
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} maxLength={100}/>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <EmailInput value={formData.email} onChange={(e) => handleChange(e)} mode="edit"/>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="managerName">
              <Form.Label>Manager's Name</Form.Label>
              <InputGroup>
                <Form.Control type="text" name="managerName" value={formData.managerName} onChange={handleChange} maxLength={100}/>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="managerEmail">
              <Form.Label>Manager's Email</Form.Label>
              <InputGroup>
                <Form.Control name="managerEmail" value={formData.managerEmail} onChange={handleChange}/>
              </InputGroup>
            </Form.Group>
          </Col>
          <h5 className="my-4">Location</h5>
          <Col md={6} className="mb-3">
            <Address1Input value={formData.address1} onChange={(e) => handleChange(e)} mode="edit"/>
          </Col>
          <Col md={6} className="mb-3">
            <Address2Input value={formData.address2} onChange={(e) => handleChange(e)} mode="edit"/>
          </Col>
          <Col md={6} className="mb-3">
            <CityInput value={formData.city} onChange={(e) => handleChange(e)} mode="edit"/>
          </Col>
          <Col sm={6} className="mb-3">
            <SelectState
              name="state"
              value={formData.state}
              onChange={handleChange}
              mode="edit"
            />
          </Col>
          <Col md={6} className="mb-3">
            <ZipCodeInput
              formData={formData}
              setFormData={setFormData}
              mode={"edit"}
            />
          </Col>
          <h5 className="my-4">Operations Management</h5>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={5}
              selectedType={formData.userSalesRep}
              onTypeChange={(value) => setFormData(prev => ({ ...prev, userSalesRep: value }))}
              nameLabel={"Sales Representative"}
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={2}
              selectedType={formData.userAreaManager}
              onTypeChange={(value) => setFormData(prev => ({ ...prev, userAreaManager: value }))}
              nameLabel={"Area Manager"}
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={6}
              selectedType={formData.userSupercontractor}
              onTypeChange={(value) => setFormData(prev => ({ ...prev, userSupercontractor: value }))}
              nameLabel={"Subcontractor"}
            />
          </Col>
          <Col md={6} className="mb-3">
            <StoreSizeInput
              value={formData.storeSize}
              onChange={(value) => setFormData((prev) => ({ ...prev, storeSize: value }))}
              mode="edit"
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectFloorType
              selectedOptions={selectedOptionsFloorType}
              onSelectChange={handleSelectChange}
              animatedComponents={animatedComponents}
              mode="edit"
            />
          </Col>
          <SelectServiceFrequency formData={formData} handleChange={handleChange} mode="edit"/>
          <Col md={6} className="mb-3">
            <SelectServiceType selectedServiceType={formData.serviceType} onServiceTypeChange={handleChange} mode="edit"/>
          </Col>
        </Row>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Update'}
        </Button>
      </Card.Body>
    </Card>
  );
};

export const ViewStoreBranchInfoForm = ({ branch }) => {
  return (
    <Card as="form" border="0" className="shadow p-3 pb-4 mb-4">
      <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
        <h5 className="mb-0">View Branch's Information</h5>
      </Card.Header>
      <Card.Body className="p-0 p-md-4">
        <Row>
          <Col md={6} className="mb-3">
            <PhoneNumberInput formData={{ phone: branch.phone_number || ""}} mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <SelectStatus value={branch.status === 1 ? "Active" : "Inactive"} mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="name">
              <Form.Label>Branch's Name</Form.Label>
              <InputGroup>
                <Form.Control type="text" name="name" value={branch.name} disabled />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <EmailInput value={branch.email || ""}  mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="managerName">
              <Form.Label>Manager's Name</Form.Label>
              <InputGroup>
                <Form.Control type="text" name="managerName" value={branch.manager_name} disabled/>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="managerEmail">
              <Form.Label>Manager's Email</Form.Label>
              <InputGroup>
                <Form.Control name="managerEmail" value={branch.manager_email} disabled/>
              </InputGroup>
            </Form.Group>
          </Col>
          <h5 className="my-4">Location</h5>
          <Col md={6} className="mb-3">
            <Address1Input value={branch.address.address1 || ""} mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <Address2Input value={branch.address.address2 || ""} mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <CityInput value={branch.address.city} mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <SelectState name="state" value={branch.address.state || ""} mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <ZipCodeInput formData={{ zipCode: branch.address.zip_code || ""}} mode="view"/>
          </Col>
          <h5 className="my-4">Operations Management</h5>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={5}
              selectedType={branch.sales_representative}
              nameLabel={"Sales Representative"}
              mode="view"
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={2}
              selectedType={branch.area_manager}
              nameLabel={"Area Manager"}
              mode="view"
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectUserType
              userTypeId={6}
              selectedType={branch.subcontractor}
              nameLabel={"Subcontractor"}
              mode="view"
            />
          </Col>
          <Col md={6} className="mb-3">
            <StoreSizeInput value={branch.store_size} mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="size">
              <Form.Label>Service Frequency</Form.Label>
              <InputGroup>
                <Form.Control type="text" name="serviceFrequency" value={branch.service_frequency} disabled />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <SelectServiceType selectedServiceType={branch.service_type.service_type_name} mode="view"/>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="floorType">
              <Form.Label>Floor Types</Form.Label>
              <Form.Select multiple defaultValue={branch.floor_types || []} disabled>
                {branch.floor_types && branch.floor_types.map((floorType, index) => (
                  <option key={index} value={floorType.floor_type_name.toLowerCase()}>
                    {floorType.floor_type_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
