import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import {
  ArchiveIcon,
  CalendarIcon,
  CameraIcon,
  CheckIcon,
  ClipboardCheckIcon,
  ClockIcon,
  EyeIcon,
  PaperClipIcon,
  PlusIcon,
  SelectorIcon,
  ShareIcon,
  TagIcon,
  UserGroupIcon,
  LockClosedIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Modal,
  Button,
  InputGroup,
  Image,
  Badge,
  FloatingLabel,
  Card,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Spinner
} from "react-bootstrap";
import api from './../service/api';
import { BASE_URL } from '../helpers/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InternalCodeInput from "./FormComponents/Inputs/InternalCodeInput";
import NoteTitleInput from "./FormComponents/Inputs/NoteTitleInput";
import NoteDescriptionInput from "./FormComponents/Inputs/NoteDescription";
import AttachmentDescriptionInput from "./FormComponents/Inputs/AttachmentDescriptionInput";
import NameInput from "./FormComponents/Inputs/NameInput";
import SelectStatus from "./FormComponents/Selects/SelectStatus";
import ClientSinceInput from "./FormComponents/Inputs/ClientSinceInput";

const ArchiveIconHtml = ReactDOMServer.renderToString(
  <ArchiveIcon className="h-50 w-auto" />
);

const CheckIconHtml = ReactDOMServer.renderToString(
  <CheckIcon className="h-50 w-auto" />
);

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export const EventModal = (props) => {
  const [title, setTitle] = useState(props.title);
  const [start, setStart] = useState(props.start);
  const [end, setEnd] = useState(props.end);

  const { show = false, edit = false, id } = props;
  const startDate = start
    ? moment(start).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");
  const endDate = end
    ? moment(end).format("YYYY-MM-DD")
    : moment(start).format("YYYY-MM-DD");

  const onTitleChange = (e) => setTitle(e.target.value);

  const onConfirm = () => {
    const sameDay = startDate === endDate;
    const finalStart = sameDay
      ? moment(startDate).toDate()
      : moment(startDate).startOf("day").toDate();
    const finalEnd = sameDay ? null : moment(endDate).endOf("day").toDate();
    const payload = { id, title, sameDay, start: finalStart, end: finalEnd };

    if (edit) {
      return props.onUpdate && props.onUpdate(payload);
    }

    return props.onAdd && props.onAdd(payload);
  };
  const onDelete = () => edit && props.onDelete && props.onDelete(id);
  const onHide = () => props.onHide && props.onHide();

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content">
        <Modal.Body>
          <Form.Group id="title" className="mb-4">
            <Form.Label>Event title</Form.Label>
            <Form.Control
              required
              autoFocus
              type="text"
              value={title}
              onChange={onTitleChange}
            />
          </Form.Group>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group id="startDate">
                <Form.Label>Select start date</Form.Label>
                <Datetime
                  timeFormat={false}
                  onChange={setStart}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={startDate}
                        onFocus={openCalendar}
                        onChange={() => { }}
                      />
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group id="endDate" className="mb-2">
                <Form.Label>Select end date</Form.Label>
                <Datetime
                  timeFormat={false}
                  onChange={setEnd}
                  isValidDate={(currDate) => currDate.isSameOrAfter(start)}
                  initialViewDate={end || start}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={endDate}
                        onFocus={openCalendar}
                        onChange={() => { }}
                      />
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="me-2" onClick={onConfirm}>
            {edit ? "Update event" : "Add new event"}
          </Button>

          {edit ? (
            <Button variant="danger" onClick={onDelete}>
              Delete event
            </Button>
          ) : null}

          <Button variant="link" className="text-gray ms-auto" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const KanbanCreateModal = (props) => {
  const { modalTitle = "Add a new card", type = "card", show = false } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const onTitleChange = (e) => setTitle(e.target.value);
  const onDescriptionChange = (e) => setDescription(e.target.value);
  const onHide = () => props.onHide && props.onHide();

  const onSubmit = () => {
    const payload = { title, description };
    return props.onSubmit && props.onSubmit(payload);
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content p-3">
        <Modal.Header className="pb-0 border-0">
          <h5 as={Modal.Title} className="fw-normal">
            {modalTitle}
          </h5>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group id="title" className="mb-3">
            <Form.Control
              required
              autoFocus
              type="text"
              value={title}
              onChange={onTitleChange}
              placeholder={`Enter a title for this ${type}…`}
            />
          </Form.Group>
          {type === "card" && (
            <Form.Group id="description" className="mb-3">
              <Form.Control
                required
                autoFocus
                multiple
                as="textarea"
                value={description}
                onChange={onDescriptionChange}
                placeholder={`Enter a description for this ${type}…`}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0">
          <Button variant="outline-gray-500" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="secondary"
            className="d-inline-flex align-items-center"
            onClick={onSubmit}
          >
            <PlusIcon className="icon icon-xs me-2" />
            Add {type}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const KanbanCopyModal = (props) => {
  const { type = "card", show = false, lists = [], ...otherProps } = props;
  const [title, setTitle] = useState(props.title ?? "");
  const [listId, setListId] = useState(props.listId ?? "");

  const onHide = () => {
    props.onHide && props.onHide();
  };

  const onSubmit = () => {
    const payload = { ...otherProps, title, listId };
    return props.onSubmit && props.onSubmit(payload);
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content p-3">
        <Modal.Header className="pb-0 border-0">
          <Modal.Title className="fw-normal">Copy {type}</Modal.Title>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group id="title" className="mb-3">
            <Form.Control
              autoFocus
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onFocus={(e) => e.target.select()}
            />
          </Form.Group>
          {type === "card" && (
            <FloatingLabel id="list" label="Copy to list.." className="mb-3">
              <Form.Select
                value={listId}
                onChange={(e) => setListId(e.target.value)}
              >
                {lists.map((l) => (
                  <option value={l.id} key={`copy-card-option-${l.id}`}>
                    {l.title}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0">
          <Button
            variant="secondary"
            className="d-inline-flex align-items-center"
            onClick={onSubmit}
          >
            <PlusIcon className="icon icon-xs me-2" />
            Create {type}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const KanbanMoveModal = (props) => {
  const { type = "card", show = false, lists = [] } = props;
  const [listId, setListId] = useState(props.listId ?? "");
  const [index, setIndex] = useState(props.index ?? 0);
  const cardList = lists.find((l) => l.id === listId);

  const onHide = () => {
    props.onHide && props.onHide();
  };

  const onSubmit = () => {
    const source = { droppableId: props.listId, index: props.index };
    const destination = { droppableId: listId, index };

    props.onSubmit && props.onSubmit({ source, destination });
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content p-3">
        <Modal.Header className="pb-0 border-0">
          <Modal.Title className="fw-normal">Move {type}</Modal.Title>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          {type === "card" ? (
            <>
              <FloatingLabel id="listId" label="List" className="mb-3">
                <Form.Select
                  value={listId}
                  onChange={(e) => setListId(e.target.value)}
                >
                  {lists.map((l) => (
                    <option value={l.id} key={`move-list-id-${l.id}`}>
                      {l.id === props.listId ? `${l.title} (current)` : l.title}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
              {cardList && (
                <FloatingLabel id="listIndex" label="Position" className="mb-3">
                  <Form.Select
                    value={index}
                    onChange={(e) => setIndex(e.target.value)}
                  >
                    {cardList.cards.map((_, ind) => (
                      <option value={ind} key={`move-list-index-${ind}`}>
                        {ind + 1}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              )}
            </>
          ) : (
            <FloatingLabel id="listIndex" label="Position" className="mb-3">
              <Form.Select
                value={index}
                onChange={(e) => setIndex(e.target.value)}
              >
                {lists.map((_, ind) => (
                  <option value={ind} key={`move-list-index-${ind}`}>
                    {ind === props.index ? `${ind + 1} (current)` : ind + 1}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0">
          <Button
            variant="secondary"
            className="d-inline-flex align-items-center"
            onClick={onSubmit}
          >
            Move {type}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const KanbanEditModal = (props) => {
  const {
    id: cardId,
    index,
    listId,
    show = false,
    author,
    labels = [],
    comments = [],
  } = props;
  const [title, setTitle] = useState(props.title ?? "");
  const [comment, setComment] = useState("");
  const [isTitleEditable, setIsTitleEditable] = useState(false);

  const toggleIsTitleEditable = () => {
    setIsTitleEditable(!isTitleEditable);
  };

  const onHide = () => {
    props.onHide && props.onHide();
  };

  const onChange = () => {
    const payload = { listId, cardId, title };

    if (title !== props.title) {
      props.onChange && props.onChange(payload);
    }

    toggleIsTitleEditable();
  };

  const onEditMembers = () => {
    props.onEditMembers && props.onEditMembers(props);
  };

  const onEditLabels = () => {
    props.onEditLabels && props.onEditLabels(props);
  };

  const onArchive = () => {
    props.onArchive && props.onArchive({ cardId, listId });
  };

  const onMove = () => {
    props.onMove && props.onMove({ listId, index });
  };

  return (
    <Modal as={Modal.Dialog} centered size="lg" show={show} onHide={onHide}>
      <Form className="modal-content p-lg-3">
        <Modal.Header className="align-items-start border-bottom">
          <div className="d-block">
            {isTitleEditable ? (
              <Form.Group id="title" className="mb-3">
                <Form.Control
                  required
                  autoFocus
                  value={title}
                  className="text-gray-900 fs-5 fw-bold border-0 px-1 py-0 m-0"
                  onChange={(e) => setTitle(e.target.value)}
                  onBlur={onChange}
                />
              </Form.Group>
            ) : (
              <h5
                className="text-gray-900 fs-5 fw-bold py-1 ps-1 mb-3"
                onClick={toggleIsTitleEditable}
              >
                {title}
              </h5>
            )}

            <div className="d-flex">
              <div className="d-block me-3 me-sm-4">
                <h5 className="fs-6 fw-bold text-gray-500">Members</h5>
                <div className="d-flex align-items-center">

                  <Button
                    variant="gray-200"
                    size="sm"
                    className="d-inline-flex align-items-center px-3 ms-1"
                    onClick={onEditMembers}
                  >
                    <PlusIcon className="icon icon-xs" />
                  </Button>
                </div>
              </div>
              <div className="d-block me-3">
                <h5 className="fs-6 fw-bold text-gray-500">Labels</h5>
                <div className="d-flex align-items-center">
                  {labels.map((l) => (
                    <Badge
                      text="white"
                      bg={l.color}
                      key={`kanban-label-${l.id}`}
                      className="rounded py-2 px-3"
                    >
                      {l.name}
                    </Badge>
                  ))}

                  <Button
                    variant="gray-200"
                    size="sm"
                    className="d-inline-flex align-items-center px-3 ms-1"
                    onClick={onEditLabels}
                  >
                    <PlusIcon className="icon icon-xs" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>

        <Modal.Body className="py-4">
          <Row>
            <Col xs={12} lg={9}>
              <Row className="mb-4">
                <Col xs="auto">
                  <div className="border border-3 rounded mb-2">
                    <Image rounded src={author.image} className="image-sm" />
                  </div>
                  <div className="text-center">
                    <PaperClipIcon className="icon icon-xs me-2" />
                    <CameraIcon className="icon icon-xs" />
                  </div>
                </Col>
                <Col>
                  <Form.Group id="comment">
                    <Form.Control
                      multiple
                      rows={3}
                      as="textarea"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Leave a comment"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4 mb-lg-0">
                {comments.map((c) => (
                  <Col xs={12} key={`kanban-comment-${c.id}`} className="mb-4">
                    <div className="bg-gray-50 border border-gray-100 rounded p-3">
                      <div className="d-flex align-items-center mb-2">
                        <h3 className="fs-6 mb-0 me-3">{c.sender}</h3>
                        <small>{moment(c.timeSent).fromNow()}</small>
                      </div>
                      <p className="text-dark mb-1">{c.message}</p>
                      <small className="hover:underline text-gray-700 me-1">
                        Edit
                      </small>
                      &middot;
                      <small className="hover:underline text-gray-700 ms-1">
                        Delete
                      </small>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={12} lg={3}>
              <div className="d-grid gap-2">
                <Button
                  variant="gray-200"
                  size="sm"
                  className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
                  onClick={onEditMembers}
                >
                  <UserGroupIcon className="icon icon-xs text-gray-500 me-2" />
                  Members
                </Button>
                <Button
                  variant="gray-200"
                  size="sm"
                  className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
                  onClick={onEditLabels}
                >
                  <TagIcon className="icon icon-xs text-gray-500 me-2" />
                  Labels
                </Button>
                <Button
                  variant="gray-200"
                  size="sm"
                  className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
                >
                  <ClipboardCheckIcon className="icon icon-xs text-gray-500 me-2" />
                  Checklist
                </Button>
                <Button
                  variant="gray-200"
                  size="sm"
                  className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
                >
                  <PaperClipIcon className="icon icon-xs text-gray-500 me-2" />
                  Attachment
                </Button>
                <Button
                  variant="gray-200"
                  size="sm"
                  className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
                >
                  <ClockIcon className="icon icon-xs text-gray-500 me-2" />
                  Due Date
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="justify-content-start border-top">
          <Button
            variant="gray-800"
            className="me-2 text-start"
            onClick={onMove}
          >
            <SelectorIcon className="icon icon-xs me-2" />
            Move
          </Button>
          <Button
            variant="gray-800"
            className="me-2 text-start"
            onClick={onArchive}
          >
            <ArchiveIcon className="icon icon-xs me-2" />
            Archive
          </Button>
          <Button variant="gray-800" className="me-2 text-start">
            <EyeIcon className="icon icon-xs me-2" />
            Watch
          </Button>
          <Button variant="gray-800" className="me-2 text-start">
            <ShareIcon className="icon icon-xs me-2" />
            Share
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const KanbanEditMembersModal = (props) => {
  const { listId, id: cardId, show = false } = props;
  const [searchValue, setSearchValue] = useState("");
  const [boardMembers, setBoardMembers] = useState([]);

  const onSearchValueChange = (e) => {
    const newSearchValue = e.target.value;
    const searchResults = boardMembers.map((bm) => ({
      ...bm,
      show: bm.name.toLowerCase().includes(newSearchValue.toLowerCase()),
    }));

    setSearchValue(newSearchValue);
    setBoardMembers(searchResults);
  };

  const onMemberClick = (id) => {
    const boardMembersUpdated = boardMembers.map((m) =>
      m.id === id ? { ...m, isAssignedToCard: !m.isAssignedToCard } : m
    );
    setBoardMembers(boardMembersUpdated);
  };

  const onHide = () => {
    props.onHide && props.onHide();
  };

  const onSubmit = () => {
    const membersSelected = boardMembers.filter((m) => m.isAssignedToCard);
    const payload = { listId, cardId, members: membersSelected };

    return props.onSubmit && props.onSubmit(payload);
  };

  return (
    <Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
      <Form className="modal-content p-3">
        <Modal.Header className="border-0 px-3 pb-0">
          <Modal.Title className="fw-normal">Members</Modal.Title>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>

        <Modal.Body className="px-3 pb-0">
          <Form.Group id="search" className="mb-3">
            <InputGroup className="search-bar">
              <Form.Control
                type="text"
                placeholder="Search board members.."
                value={searchValue}
                onChange={onSearchValueChange}
              />
            </InputGroup>
          </Form.Group>

          <div className="px-3">
            {boardMembers
              .filter((m) => m.show)
              .map((m) => (
                <Row
                  key={`board-member-${m.id}`}
                  className="kanban-card-member border-bottom py-2"
                  onClick={() => onMemberClick(m.id)}
                >
                  <Col xs={2}>
                    <Image src={m.image} className="avatar-md rounded-circle" />
                  </Col>
                  <Col
                    xs={8}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <h4 className="fs-6 text-dark mb-0">{m.name}</h4>
                  </Col>
                  {m.isAssignedToCard && (
                    <Col xs={2} className="d-flex align-items-center">
                      <CheckIcon className="icon icon-sm text-success" />
                    </Col>
                  )}
                </Row>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pb-0">
          <Button
            variant="secondary"
            className="d-inline-flex align-items-center"
            onClick={onSubmit}
          >
            Confirm members
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const KanbanEditLabelsModal = (props) => {
  const { listId, id: cardId, show = false } = props;
  const [searchValue, setSearchValue] = useState("");
  const [boardLabels, setBoardLabels] = useState([]);

  const onSearchValueChange = (e) => {
    const newSearchValue = e.target.value;
    const searchResults = boardLabels.map((bm) => ({
      ...bm,
      show: bm.name.toLowerCase().includes(newSearchValue.toLowerCase()),
    }));

    setSearchValue(newSearchValue);
    setBoardLabels(searchResults);
  };

  const onLabelClick = (id) => {
    const boardLabelsUpdated = boardLabels.map((m) =>
      m.id === id ? { ...m, isAssignedToCard: !m.isAssignedToCard } : m
    );
    setBoardLabels(boardLabelsUpdated);

    const labelsSelected = boardLabelsUpdated.filter((l) => l.isAssignedToCard);
    const payload = { listId, cardId, labels: labelsSelected };
    props.onSubmit && props.onSubmit(payload);
  };

  const onHide = () => {
    props.onHide && props.onHide();
  };

  return (
    <Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
      <Form className="modal-content p-3">
        <Modal.Header className="border-0 px-3 pb-0">
          <Modal.Title className="fw-normal">Labels</Modal.Title>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>

        <Modal.Body className="px-3 pb-0">
          <Form.Group id="search" className="mb-3">
            <InputGroup className="search-bar">
              <Form.Control
                type="text"
                placeholder="Search labels.."
                value={searchValue}
                onChange={onSearchValueChange}
              />
            </InputGroup>
          </Form.Group>

          <div className="px-3 py-2">
            {boardLabels
              .filter((l) => l.show)
              .map((l) => (
                <Row key={`label-${l.id}`} className="my-1">
                  <Badge
                    bg={l.color}
                    className="kanban-card-label py-2 px-3"
                    onClick={() => onLabelClick(l.id)}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="fs-6 text-white mb-0">{l.name}</h4>
                      {l.isAssignedToCard && (
                        <CheckIcon className="icon icon-sm" />
                      )}
                    </div>
                  </Badge>
                </Row>
              ))}
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};
export const UserResetPassWordModel = (props) => {
  const { refreshUsers } = props;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [missingRequirements, setMissingRequirements] = useState({
    minLength: true,
    number: true,
    uppercase: true,
    specialChar: true,
  });
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const updatePasswordRequirements = (password) => {
    const minLength = 8;
    const maxLength = 12;
    const regexNumber = /[0-9]/;
    const regexUpperCase = /[A-Z]/;
    const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    setMissingRequirements({
      minLength: password.length < minLength || password.length > maxLength,
      number: !regexNumber.test(password),
      uppercase: !regexUpperCase.test(password),
      specialChar: !regexSpecialChar.test(password),
    });
  };

  const validatePasswords = () => {
    if (!newPassword || !confirmPassword) {
      return "Both password fields are required.";
    }
    if (newPassword !== confirmPassword) {
      return "Passwords do not match.";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) return;

    const validationError = validatePasswords();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    if (
      missingRequirements.minLength ||
      missingRequirements.number ||
      missingRequirements.uppercase ||
      missingRequirements.specialChar
    ) {
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const payload = {
      new_password: newPassword,
      new_password_confirmation: confirmPassword,
    };

    const textMessage = "Do you really want to reset this password?";
    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await api.put(`${BASE_URL}/user/password/${props.userId}`, payload);
        if (refreshUsers) {
            refreshUsers();
          }
        props.onHide && props.onHide();

        await SwalWithBootstrapButtons.fire("Success", "Password reset successfully.", "success");

      } catch (error) {
        console.error("Error resetting password:", error);
        if (error.response && error.response.data && error.response.data.message) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage("Failed to reset password. Please try again.");
          }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCapsLock = (e) => {
    setIsCapsLockOn(e.getModifierState("CapsLock"));
  };

  return (
    <Modal as={Modal.Dialog} centered show={props.show} onHide={props.onHide}>
      <Form className="modal-content p-3">
        <Modal.Header className="pb-0 border-0">
          <h5 className="fw-normal">Reset Password</h5>
          <Button variant="close" onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form>
            <Form.Group id="newPassword" className="mb-4">
              <Form.Label>
                New Password
                <span className="text-danger"> *</span>
              </Form.Label>
              {errorMessage && (
              <div className="alert alert-danger">
                <strong>Error:</strong> {errorMessage}
              </div>
            )}
              <InputGroup>
                <InputGroup.Text>
                  <LockClosedIcon className="icon icon-xs text-gray-600" />
                </InputGroup.Text>
                <Form.Control
                  name="newPassword"
                  required
                  placeholder="New Password"
                  type="password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    updatePasswordRequirements(e.target.value);
                    setPasswordsMatch(e.target.value === confirmPassword);
                  }}
                  value={newPassword}
                  onKeyDown={handleCapsLock}
                  onKeyUp={handleCapsLock}
                />
              </InputGroup>
              {(missingRequirements.minLength ||
                missingRequirements.number ||
                missingRequirements.uppercase ||
                missingRequirements.specialChar) && (
                <ul style={{ fontSize: "0.6rem" }}>
                  {missingRequirements.minLength && <li><small>Password must be between 8 and 12 characters</small></li>}
                  {missingRequirements.number && <li><small>Password must contain at least one number.</small></li>}
                  {missingRequirements.uppercase && <li><small>Password must contain at least one uppercase letter.</small></li>}
                  {missingRequirements.specialChar && <li><small>Password must contain at least one special character.</small></li>}
                </ul>
              )}
            </Form.Group>

            <Form.Group id="confirmPassword" className="mb-4">
              <Form.Label>
                Confirm Password
                <span className="text-danger"> *</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <LockClosedIcon className="icon icon-xs text-gray-600" />
                </InputGroup.Text>
                <Form.Control
                  name="confirmPassword"
                  required
                  placeholder="Confirm Password"
                  type="password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setPasswordsMatch(newPassword === e.target.value);
                  }}
                  value={confirmPassword}
                  onKeyDown={handleCapsLock}
                  onKeyUp={handleCapsLock}
                />
              </InputGroup>
              {!passwordsMatch && (
                <small><strong>Passwords do not match.</strong></small>
              )}
              {isCapsLockOn && (
                <div className="text-warning mb-3">
                  <small>Warning: Caps Lock is ON!</small>
                </div>
              )}
            </Form.Group>

            <div className="d-grid">
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={
                  isLoading ||
                  missingRequirements.minLength ||
                  missingRequirements.number ||
                  missingRequirements.uppercase ||
                  missingRequirements.specialChar ||
                  !passwordsMatch
                }
              >
                {isLoading ? "Resetting..." : "Reset Password"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export const UserViewModal = (props) => {
    const { modalTitle = "View User Information", show = false } = props;
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const onHide = () => props.onHide && props.onHide();

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await api.get(`${BASE_URL}/user/${props.userId}`);
          setUser(response.data.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      if (props.userId) {
        fetchData();
      }
    }, [props.userId]);

    return (
      <Modal as={Modal.Dialog} centered show={show} onHide={onHide} size="lg" className="user-view-modal">
        <Form className="modal-content p-3">
          <Modal.Header className="pb-0 border-0 d-flex justify-content-between align-items-center">
             <h5 as={Modal.Title} className="fw-normal">
             {modalTitle}
          </h5>
           <Button variant="close" onClick={onHide} />
          </Modal.Header>

          <Modal.Body className="pb-3">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="name">
                    <Form.Label>Full Name</Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        value={user.name || ''}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="phone">
                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        value={user.phone_number || ''}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        value={user.email || ''}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <SelectStatus value={user.status === 1 ? "Active" : "Inactive"} mode="view"/>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="userType">
                    <Form.Label>User Type</Form.Label>
                    <InputGroup>
                      <Form.Control required type="text" value={user.user_type_name || ''} disabled />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Modal.Body>
           <Modal.Footer className="justify-content-start border-0 pt-0">
           <Button variant="outline-gray-500" onClick={onHide}>
             Close
           </Button>
         </Modal.Footer>
        </Form>
      </Modal>
    );
};

export const CreateNewNoteModel = (props) => {
  const { modalTitle = "Add New Note", show = false, onHide, entityName, entityId, refreshNotes } = props;

  const [noteTitle, setNoteTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      entity_name: entityName,
      entity_id: entityId,
      note_title: noteTitle,
      description
    };

    if (loading) return;
    setLoading(true);

    try {
      await api.post(`${BASE_URL}/notes`, payload);

      setNoteTitle("");
      setDescription("");

      await SwalWithBootstrapButtons.fire(
        "Note created successfully!",
        "",
        "success"
      );
      if (refreshNotes) {
        refreshNotes();
      }

      onHide();
    } catch (error) {
      console.error("Error creating note:", error);
      await SwalWithBootstrapButtons.fire(
        "Error creating note",
        error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue creating your note. Please try again later.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content p-3" onSubmit={onSubmit}>
        <Modal.Header className="pb-0 border-0">
          <h5 className="fw-normal">{modalTitle}</h5>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col md={12} className="mb-3">
              <NoteTitleInput
                noteTitle={noteTitle}
                onChange={(e) => setNoteTitle(e.target.value)}
                isNoteTitleRequired={true}
              />
            </Col>
            <Col md={12} className="mb-3">
              <NoteDescriptionInput
                note={description}
                onChange={(e) => setDescription(e.target.value)}
                isNoteTitleProvided={true}
              />
            </Col>
            <div className="d-grid pt-3">
              <Button variant="primary" type="submit">
                Create
              </Button>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0"></Modal.Footer>
      </Form>
    </Modal>
  );
};

export const CreateNewAttachmentModel = (props) => {
  const { entityName, entityId, modalTitle = "Add New Attachment", show = false, refreshAttachments } = props;
  const onHide = () => props.onHide && props.onHide();
  const [loading, setLoading] = useState(false);
  const [attachmentTypes] = useState(props.attachmentTypes)
  const [attachmentsToAdd, setAttachmentsToAdd] = useState([props.attachmentType])
  const [formData, setFormData] = useState([])
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const today = moment().format("YYYY-MM-DD");
  const startDate = start
    ? moment(start).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");
  const endDate = end
    ? moment(end).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  function availableAttachmentTypes() {
    return attachmentTypes.filter(item1 =>
      !attachmentsToAdd.some(item2 => item1.id === item2.id)
    );
  }

  useEffect(() => {
    addItemInData(props.attachmentType.id, { type_name: props.attachmentType.name })
  }, [props.attachmentType.id, props.attachmentType.name])

  const [attachments] = useState(
    Array(3).fill({ selectedFile: null }) // Estado inicial com agrupamentos
  );

  const addNewAttachment = (data) => {
    setAttachmentsToAdd((prev) => [...prev, data]);
    addItemInData(data.id, { type_name: data.name })
  }

  function addItemInData(id, data) {
    setFormData((prev) => {
      const exists = prev.some((v) => v.id === id);

      if (exists) {
        return prev.map((v) =>
          v.id === id ? { ...v, ...data } : v
        );
      }

      return [...prev, { id, ...data }];
    });
  }

  function getFormValue(id, fieldName) {
    const result = formData.find(v => v.id === id)

    if (result === undefined) {
      return ''
    }

    return result[fieldName] || ''
  }

  function removeCard(id) {
    if (formData.length === 1) {
      return onHide();
    }

    setFormData((prev) => prev.filter((item) => item.id !== id));
    setAttachmentsToAdd((prev) => prev.filter((item) => item.id !== id));
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;
    setLoading(true);

    await processAttachments();
  };

  async function processAttachments() {
    let isValid = true;
    try {
      const allowedTypes = [
          'application/msword',
          'application/pdf',
          'text/csv',
          'application/vnd.ms-excel',
          'image/jpeg',
          'image/png',
          'image/heic'
      ];
      const maxFileSize = 10 * 1024 * 1024;
      const requests = formData.map(async (data) => {
        const invalidFiles = Array.from(data.files).filter(file => {
            const fileTypeValid = allowedTypes.includes(file.type);
            const fileSizeValid = file.size <= maxFileSize;
            return !(fileTypeValid && fileSizeValid);
        });

        if (invalidFiles.length > 0) {
            setLoading(false);
            isValid = false;
            await SwalWithBootstrapButtons.fire(
                "Invalid Files",
                `<p>Some files have invalid types or exceed the 10MB size limit. Please check your files.</p>
                <p><strong>Valid file extensions:</strong> .doc, .pdf, .csv, .xls, .jpeg, .png, .heic</p>`,
                "error"
              );
            return ;
        }

        if (props.attachmentType.id === 3 && (start == null || end == null)) {
          setLoading(false);
          isValid = false;
          await SwalWithBootstrapButtons.fire(
              "Invalid Date",
              "Please select a valid start and  due date.",
              "error"
            );
          return ;
        }

        const responseInfo = await api.post(`${BASE_URL}/attachments/infos`, {
            entity: entityName,
            entity_id: entityId,
            title: data.attachment_title,
            attachment_type_id: data.id,
            description: data.description,
            start_at: start ? start.format('YYYY-MM-DD') : null,
            expire_at: end ? end.format('YYYY-MM-DD') : null
        });

        const fileRequests = Array.from(data.files).map(async (file) => {

            const payload = {
            attachment_info_id: responseInfo.data.id,
            files: [file],
            attachment_type_id: data.id,
            };

            try {
              const response = await api.post(`${BASE_URL}/attachments`, payload, {
                headers: {
                'Content-Type': 'multipart/form-data',
                },
            });
            refreshAttachments(response.data.data);

            } catch (error) {
            console.error("Error creating attachment:", error.response ? error.response.data : error.message);
            await SwalWithBootstrapButtons.fire(
                "Error creating attachment",
                error.response
                ? error.response.data.message || "An unexpected error occurred."
                : "There was an issue creating your attachment. Please try again later.",
                "error"
            );
            throw error;
            }
        });
        return Promise.all(fileRequests);
      });

      if (isValid) {
        await Promise.all(requests);
        await SwalWithBootstrapButtons.fire("Attachment created successfully!", "", "success");
        onHide();
      }
    } catch (error) {
      console.error("Error during attachment processing:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function removeFile(event, index, attachmentYype) {
    const fileList = getFormValue(attachmentYype, 'files')

    const dataTransfer = new DataTransfer();

    const arrayList = Array.from(fileList);

    arrayList.forEach((file, i) => {
      if(index !== i) {
        dataTransfer.items.add(file);
      }
    });

    addItemInData(attachmentYype, { files: dataTransfer.files })
    event.target.closest('div').querySelector('input').files = dataTransfer.files
  }

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide} size="lg">
      <Form className="modal-content p-3" onSubmit={onSubmit}>
        <Modal.Header className="pb-0 border-0">
          <h5 className="fw-normal">{modalTitle}</h5>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          {attachmentsToAdd.map((data, i) => (
            <Card border="primary" key={i} className="mb-2">
              <Card.Header className="d-flex justify-content-between">
                <div>
                  <Badge bg="primary">{data.name}</Badge>
                </div>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className="m-0">Delete</Tooltip>}
                >
                  <Card.Link className="ms-2" onClick={() => removeCard(data.id)}>
                    <XCircleIcon className="icon icon-xs text-danger" />
                  </Card.Link>
                </OverlayTrigger>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={12} className="mb-3">
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="attachment_title">
                      <Form.Label>Attachment Title
                        <span className="text-danger"> * </span>
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Type down a Title"
                          value={getFormValue(data.id, 'attachment_title')}
                          onChange={(e) => {
                            addItemInData(data.id, { attachment_title: e.target.value })
                          }
                          }
                          maxLength={100}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                  <Form.Group id="file">
                    <Form.Label>Choose File
                      <span className="text-danger"> * </span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(i) => {
                        addItemInData(data.id, { files: i.target.files })
                      }}
                      required
                      multiple
                    />
                    <ul>
                    { Array.from(getFormValue(data.id, 'files')).length > 0 ? Array.from(getFormValue(data.id, 'files')).map((value, i) =>
                        <li key={i} >
                          {value.name}
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip className="m-0">Delete</Tooltip>}
                          >
                            <Card.Link className="ms-2" onClick={(e) => removeFile(e, i, data.id)}>
                              <XCircleIcon className="icon icon-xs text-danger" />
                            </Card.Link>
                          </OverlayTrigger>
                        </li>
                    ): null}
                    </ul>
                  </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <AttachmentDescriptionInput
                      description={getFormValue(data.id, 'description')}
                      onChange={(e) => {
                        addItemInData(data.id, { description: e.target.value })
                      }
                      }
                      isDisabled={false}
                    />
                  </Col>
                  {props.attachmentType.id === 3 && (
                    <>
                      <Col xs={12} lg={6}>
                        <Form.Group id="startDate">
                          <Form.Label>Start date</Form.Label>
                          <Datetime
                            timeFormat={false}
                            onChange={setStart}
                            renderInput={(props, openCalendar) => (
                              <InputGroup>
                                <InputGroup.Text>
                                  <CalendarIcon className="icon icon-xs" />
                                </InputGroup.Text>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="YYYY-MM-DD"
                                  value={startDate}
                                  onFocus={openCalendar}
                                  onChange={() => { }}
                                />
                              </InputGroup>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={6}>
                        <Form.Group id="endDate" className="mb-2">
                          <Form.Label>Due date</Form.Label>
                          <Datetime
                            timeFormat={false}
                            onChange={setEnd}
                            isValidDate={(currDate) => currDate.isSameOrAfter(start)}
                            initialViewDate={end || start}
                            renderInput={(props, openCalendar) => (
                              <InputGroup>
                                <InputGroup.Text>
                                  <CalendarIcon className="icon icon-xs" />
                                </InputGroup.Text>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="YYYY-MM-DD"
                                  value={endDate}
                                  onFocus={openCalendar}
                                  onChange={() => { }}
                                />
                              </InputGroup>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          ))}
          <Dropdown className={availableAttachmentTypes().length === 0 ? 'd-none' : undefined}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <PlusIcon className="icon icon-xs me-2" /> Attachment
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {availableAttachmentTypes().map((value) => (
                <Dropdown.Item
                  as={'div'}
                  key={value.id}
                  onClick={() => addNewAttachment(value)}
                >{value.name}</Dropdown.Item>

              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Col md={12} className="mb-3">
            <div className="d-grid pt-3">
              <Button variant="primary" type="submit" disabled={loading}>
                Create
              </Button>
            </div>
          </Col>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0"></Modal.Footer>
      </Form>
    </Modal>
  );
};

export const StoreEditModal = (props) => {
  const {
    modalTitle = "Update Store Information",
    show = false,
    storeId,
    internalCodeId,
    internalCodeName,
    storeName,
    storeStatus,
    clientSince,
    refreshStores
  } = props;
  const [store, setStore] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const onHide = () => props.onHide && props.onHide();
  const [formData, setFormData] = useState({
    internalCode: '',
    name: '',
    status: '1',
    since: ''
  });

  useEffect(() => {
    setFormData({
      internalCode: internalCodeName,
      name: storeName,
      status: storeStatus,
      since: clientSince,
    });
  }, [storeId, internalCodeId, internalCodeName, storeName, storeStatus, clientSince]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const validateInternalCode = (code) => {
    const regex = /^[A-Za-z]{4}$/;
    return regex.test(code);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) return;
    setIsLoading(true);

    setError('');

    if (!validateInternalCode(formData.internalCode)) {
      Swal.fire({
        icon: 'warning',
        title: 'Internal Code Required',
        text: 'Internal Code must be exactly 4 alphabetic characters.',
      });
      setError('Internal Code must be exactly 4 alphabetic characters.');
      setIsLoading(false);
      return;
    }

    const payload = {
      internal_code: formData.internalCode,
      name: formData.name,
      since: formData.since,
      status: formData.status
    };

    const textMessage = "Do you really want to update this store?";
    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: CheckIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await api.put(`${BASE_URL}/store/${storeId}/${internalCodeId}`, payload);

        if (refreshStores) {
          refreshStores();
        }

        await SwalWithBootstrapButtons.fire('Edit', 'Store successfully updated', 'success');

        onHide();
      } catch (error) {
        console.error('Update failed:', error);
        await SwalWithBootstrapButtons.fire("Error", error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue. Please try again later.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content p-3" onSubmit={handleSubmit}>
        <Modal.Header className="pb-0 border-0">
          <h5 as={Modal.Title} className="fw-normal">
            {modalTitle}
          </h5>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col md={12} className="mb-3">
              <NameInput value={formData.name} onChange={handleChange} />
            </Col>
            <Col md={12} className="mb-3">
              <SelectStatus value={formData.status} onChange={handleChange} />
            </Col>
            <Col md={6} className="mb-3">
              <InternalCodeInput value={formData.internalCode} onChange={handleChange} />
            </Col>
            <Col md={6} className="mb-3">
              <ClientSinceInput
                value={formData.since}
                onChange={(value) => setFormData((prev) => ({ ...prev, since: value }))} />
            </Col>
            <Col md={12} className="mb-3">
              <div className="d-grid pt-3">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Update'}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0"></Modal.Footer>
      </Form>
    </Modal>
  );
};

export const StoreViewModal = (props) => {
  const { modalTitle = "View Store Information", show = false } = props;
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(true);
  const onHide = () => props.onHide && props.onHide();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`${BASE_URL}/store/${props.storeId}`);
        setStore(response.data.data);
      } catch (error) {
        console.log(error);
      }finally{
        setLoading(false);
      }
    };

    fetchData();
  }, [props.storeId]);

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content p-3">
        <Modal.Header className="pb-0 border-0">
          <h5 as={Modal.Title} className="fw-normal">
            {modalTitle}
          </h5>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
                <Row>
                    <Col md={12} className="mb-3">
                    <NameInput value={store.name} mode="view" />
                    </Col>
                    <Col md={12} className="mb-3">
                    <SelectStatus value={store.status === 1 ? "Active" : "Inactive"} mode="view" />
                    </Col>
                    <Col md={6} className="mb-3">
                    <InternalCodeInput value={store.internal_code} mode="view" />
                    </Col>
                    <Col md={6} className="mb-3">
                    <ClientSinceInput value={store.since} mode="view" />
                    </Col>
                </Row>
             )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0">
          <Button variant="outline-gray-500" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const UpgradeToBranchModel = (props) => {
  const { modalTitle = "Upgrade to Branch", show = false, prospectId, company, service_type_id, refreshProspects } = props;
  const [stores, setStores] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState('');
  const [selectedServiceTypeId, setSelectedServiceTypeId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const onHide = () => props.onHide && props.onHide();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storesData = await api.get(`${BASE_URL}/stores`);
        const serviceTypesData = await api.get(`${BASE_URL}/serviceType`);

        setStores(storesData.data.data);
        setServiceTypes(serviceTypesData.data.data);
      } catch (error) {
        console.log(error);
        await SwalWithBootstrapButtons.fire(
          "Error loading stores or service types",
          error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue loading the stores or the service types. Please try again later.",
          "error"
        );
      }
    };

    fetchData();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) return;
    setIsLoading(true);

    const payload = {
      prospect_id: prospectId,
      store_id: selectedStoreId,
      service_type_id: service_type_id,
    }
    const textMessage = "Do you really want to upgrade this client?";
    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {

        await api.post(`${BASE_URL}/prospect/upgrade-to-branch`, payload);

        if (refreshProspects) {
          refreshProspects();
        }

        await SwalWithBootstrapButtons.fire("Created", "Branch successfully created", "success");

        onHide();
      } catch (error) {
        console.error("Upgrade failed:", error);
        await Swal.fire({
            icon: 'error',
            title: 'Upgrade failed',
            text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue upgrading the prospective client. Please try again later.",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };
  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );
  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content p-3" onSubmit={onSubmit}>
        <Modal.Header className="pb-0 border-0">
          <h5 className="fw-normal">{modalTitle}</h5>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="name">
                <RequiredLabel label="Name" />
                <Form.Control name="storeName" value={company} readOnly />
              </Form.Group>
            </Col>
            <Col md={12} className="mb-3">
              <Form.Group id="storeInternalCode">
                <Form.Label>
                  Store  <span className="text-danger"> * </span>
                </Form.Label>
                <InputGroup>
                  <Form.Select onChange={(e) => setSelectedStoreId(e.target.value)} name="store" value={selectedStoreId} required>
                    <option value="">Select a store</option>
                    {stores.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0">
          <Col md={12} className="d-grid">
            <Button variant="primary" type="submit" disabled={isLoading} >
              {isLoading ? "Upgrading..." : "Upgrade"}
            </Button>
          </Col>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const UpgradeToStoreModel = (props) => {
  const { modalTitle = "Upgrade to Store", show = false, prospectId, company, refreshProspects } = props;
  const [formData, setFormData] = useState({
    internalCode: '',
    since: '',
    status: true,
    storeName: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onHide = () => props.onHide && props.onHide();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === 'status' ? value === 'true' : value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      since: moment(date).format('YYYY-MM-DD')
    }));
  };

  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  // Função para validar o internalCode
  const validateInternalCode = (code) => {
    const regex = /^[A-Za-z]{4}$/;
    return regex.test(code);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    setError('');

    if (!validateInternalCode(formData.internalCode)) {
      Swal.fire({
        icon: 'warning',
        title: 'Internal Code Required',
        text: 'Internal Code must be exactly 4 alphabetic characters.',
      });
      setError('Internal Code must be exactly 4 alphabetic characters.');
      setIsLoading(false);  // Desabilita o carregamento
      return;
    }

    const textMessage = "Do you really want to upgrade this client?";
    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      title: "Are you sure?",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      const payload = {
        prospect_id: prospectId,
        name: company,
        status: formData.status,
        internal_code: formData.internalCode,
        since: formData.since
      };

      try {
        await api.post(`${BASE_URL}/prospect/upgrade-to-store`, payload);
        if (refreshProspects) {
          refreshProspects();
        }

        await SwalWithBootstrapButtons.fire('Created', 'Store created successfully', 'success');
        onHide();
      } catch (error) {
        console.error('Error submitting form:', error);
        await Swal.fire({
          icon: 'error',
          title: 'Error upgrading prospective client',
          text: error.response ? error.response.data.message || "An unexpected error occurred." : "There was an issue upgrading the prospective client. Please try again later."
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content p-3" onSubmit={handleSubmit}>
        <Modal.Header className="pb-0 border-0">
          <h5 className="fw-normal">{modalTitle}</h5>
          <Button variant="close" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="name">
                <RequiredLabel label="Name" />
                <Form.Control name="storeName" value={company} readOnly />
              </Form.Group>
            </Col>
            <Col md={12} className="mb-3">
              <InternalCodeInput
                value={formData.internalCode}
                onChange={handleChange}
                mode="create"
              />
              {error && <div className="text-danger mt-2">{error}</div>}
            </Col>
            <Col md={6} className="mb-3">
              <ClientSinceInput value={formData.since || ''} onChange={handleDateChange} mode="create" />
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="status" name="status">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12} className="mb-3">
              <div className="d-grid pt-3">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isLoading}  // Desabilita o botão enquanto estiver carregando
                >
                  {isLoading ? 'Upgrading...' : 'Upgrade'}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0 pt-0"></Modal.Footer>
      </Form>
    </Modal>
  );
};

export const ModalDescription = (props) => {
  const { name, description, createdAt, user, show, handleClose } = props;

  return (
    <Modal centered show={show} onHide={handleClose} size="md">
      <Modal.Header className="pb-0 border-0" closeButton>
        <Modal.Title>
        {/* <span>Details</span> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto', padding: '20px' }}>
          {/* Título do anexo */}
          <Card style={{ marginBottom: '20px', padding: '15px', borderRadius: '8px'}}>
            <Card.Header style={{ borderRadius: '5px' }}>
              <span style={{fontWeight: 'bold' }}>
                {name}
              </span>
            </Card.Header>
            <Card.Body style={{ padding: '10px' }}>
              {/* Descrição do anexo */}
              <p style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}>
                {description}
              </p>
            </Card.Body>
          </Card>
        <div style={{ textAlign: 'right', fontSize: '14px', color: '#7f8c8d' }}>
            <small>{`Created by ${user} on ${createdAt}`}</small>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: '1px solid #bdc3c7' }}>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalDescriptionAttachments = ({ show, handleClose, data }) => {

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};
    return (
      <Modal centered show={show} onHide={handleClose} size="lg">
        <Modal.Header className="pb-0 border-0" closeButton>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto', padding: '20px' }}>
          <Card style={{ marginBottom: '20px', padding: '15px', borderRadius: '8px'}}>
            <Card.Header style={{ borderRadius: '5px' }}>
              <span style={{fontWeight: 'bold' }}>
                {data.attachment_title}
              </span>
            </Card.Header>
            <Card.Body style={{ padding: '10px' }}>
              <p style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}>
                {data.attachment_description}
              </p>
            </Card.Body>
          </Card>
          <Card style={{ marginBottom: '20px', padding: '15px', borderRadius: '8px'}}>
            <Card.Header style={{ borderRadius: '5px'}}>
              <span style={{fontWeight: 'bold' }}>Attached Files</span>
            </Card.Header>
            <Card.Body style={{ padding: '10px' }}>
              <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
                {data.attachments.map((attachment) => (
                  <li key={attachment.id} style={{ marginBottom: '10px', fontSize: '1rem'}}>
                    <strong>{attachment.name}</strong>
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Card>
          <div style={{ textAlign: 'right', fontSize: '0.875rem', color: '#7f8c8d' }}>
            <small>{`Created by ${data.user.name} on ${formatDate(data.created_at)}`}</small>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: '1px solid #bdc3c7' }}>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
