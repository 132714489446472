export const RoutesPath = {
    // pages
    Presentation: { path: "/" },

    Users: { path: "/users" },
    NewUserForm: { path: "/users/new-user" },
    EditUserForm: {path: "/users/edit-user/:id"},

    Prospects: {path:"/clients/prospects"},
    NewProspectForm:{ path: "/clients/prospects/new-prospect"},
    EditProspectForm:{path: "/clients/prospects/edit-prospect/:id"},
    ViewProspectForm:{path: "/clients/prospects/view-prospect/:id"},
    ViewProspectStatusHistory:{path: "/clients/prospects/view-status-history/:id/:company"},

    Stores:{path: "/clients/stores"},
    NewStoreForm:{path: "/clients/stores/new-store"},

    StoresBranches: {path:"/clients/stores/:storeId/branches"},
    NewStoreBranchForm: {path:"/clients/stores/:storeId/branches/new-branch"},
    EditStoreBranchForm:{path:"/clients/stores/branches/edit-branch/:id"},
    ViewStoreBranchForm:{path:"/clients/stores/branches/view-branch/:id"},
    Attachments: { path: "/attachments/:entity/:entityId" },
    Contracts: { path: "/contracts/:entity/:entityId" },
    Notes: { path: "/notes/:entity/:entityId" },
    Signin: { path: "/login" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password/:token" },
    ChangePasswordAtFirstLogin: {path: "/changePassword"},
    NotFound: { path: "/404" },
    Unauthorized: { path: "/unauthorized"},
    ServerError: { path: "/500" },
};

export const requiredPermissions = [
    { pathRegex : /^\/clients\/prospects$/, permission: 'getAllProspects'},
    { pathRegex: /^\/clients\/prospects\/new-prospect$/, permission: 'createProspect' },
    { pathRegex: /^\/clients\/prospects\/edit-prospect\/\d+$/, permission: 'updateProspect' },
    { pathRegex : /^\/clients\/prospects\/view-prospect\/\d+$/, permission: 'getAllProspects'},
    { pathRegex : /^\/clients\/prospects\/view-status-history\/\d+\/\w+$/, permission: 'getProspectStatusHistory'},
    
    { pathRegex : /^\/clients\/stores$/, permission: 'getAllStores'},
    { pathRegex : /^\/clients\/stores\/new-store$/, permission: 'createStore'},
    
    { pathRegex : /^\/clients\/stores\/(\d+)\/branches$/, permission: 'getAllBranches'},
    { pathRegex : /^\/clients\/stores\/(\d+)\/branches\/new-branch$/, permission: 'createBranch'},
    { pathRegex : /^\/clients\/stores\/branches\/edit-branch\/(\d+)$/, permission: 'updateBranch'},
    { pathRegex : /^\/clients\/stores\/branches\/view-branch\/(\d+)$/, permission: 'getAllBranches'},
    
    { pathRegex : /^\/notes\/(\w+)\/(\d+)$/, permission: 'getAllNotes'},
    { pathRegex : /^\/attachments\/(\w+)\/(\d+)$/, permission: 'getAllAttachments'},
    { pathRegex : /^\/contracts\/(\w+)\/(\d+)$/, permission: 'getAllContracts'},
  
    { pathRegex : /^\/users$/, permission: 'getAllUsers'},
    { pathRegex : /^\/users\/new-user$/, permission: 'createUser'},
    { pathRegex : /^\/users\/edit-user\/\d+$/, permission: 'updateUser'},
];