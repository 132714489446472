import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon, EyeIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";  // useLocation para acessar a URL
import { BASE_URL } from '../../../helpers/config';
import api from '../../../service/api';
import { StatusHistoryTable } from "components/Tables";
import { RoutesPath } from "routes";
const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const location = useLocation();
  const pathParts = location.pathname.split("/");

const prospectId = pathParts[pathParts.length - 2];
const company = decodeURIComponent(pathParts[pathParts.length - 1]);
  const [prospects, setProspects] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const selectedProspectsIds = prospects.filter(p => p.isSelected).map(p => p.id);
  const totalProspects = prospects.length;
  const allSelected = selectedProspectsIds.length === totalProspects;


  const fetchData = async () => {
    try {
        const response = await api.get(`${BASE_URL}/prospect/status-history/${prospectId}`);
        let data = Object.values(response.data.data);
        const modifiedProspects = data.map(p => ({...p, isSelected: false, show: true }));
        setProspects(modifiedProspects);
    } catch (error) {
    console.log(error);
    await SwalWithBootstrapButtons.fire({
        icon: 'error',
        title: 'Error loading statuses',
        text: 'Unable to load statuses. Please try again later.',
      });
    }
};

useEffect(() => {
fetchData();
}, [prospectId]);



  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value.toLowerCase();
    const newProspects = prospects.map(p => {
        const createdAt = p.created_at?.toLowerCase() || '';
        const updatedAt = p.updated_at?.toLowerCase() || '';
        const status = p.status_name.toLowerCase();
        const userName = p.user.name.toLowerCase();

        const shouldShow = createdAt.includes(newSearchValue) ||
            status.includes(newSearchValue) ||
            updatedAt.includes(newSearchValue) ||
            userName.includes(newSearchValue);
        return { ...p, show: shouldShow };
    });

    setSearchValue(newSearchValue);
    setProspects(newProspects);
};

const changeStatusValueFilter = (e) => {
    const newStatusValue = e.target.value.toLowerCase();
    const newProspects = prospects.map(p => {
      const statusMatches = p.status?.status_name.toLowerCase() === newStatusValue;
      return {
        ...p,
        show: newStatusValue === "all" || statusMatches
      };
    });

    setStatusValue(newStatusValue);
    setProspects(newProspects);
};

const filteredProspects = prospects.filter(p => {
    const matchesStatus = statusValue === "all" || p.status_name === statusValue;
    const matchesSearch = p.created_at.toLowerCase().includes(searchValue)
      || p.updated_at.toLowerCase().includes(searchValue)
      || p.user.name.toLowerCase().includes(searchValue)
      || p.status_name.toLowerCase().includes(searchValue);

    return matchesStatus && matchesSearch;
  });

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>
            <Link to={RoutesPath.Prospects.path}>Prospect</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Status History</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Status History</h4>
          <p className="mb-0">{company}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup className="ms-2 ms-lg-3">
            <Button variant="outline-gray-600" size="sm">Export</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search status history"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusValue} className="fmxw-200 d-none d-md-inline" onChange={changeStatusValueFilter}>
              <option value="all">All</option>
              <option value="approved">Approved</option>
              <option value="contacted">Contacted</option>
              <option value="follow_up_call">Follow-up call</option>
              <option value="proposal_accepted">Proposal Accepted</option>
              <option value="proposal_rejected">Proposal Rejected</option>
              <option value="proposal_submitted">Proposal Submitted</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <CogIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <StatusHistoryTable
        prospects={filteredProspects}
        refreshed={fetchData}
      />
    </>
  );
};
